import {Button, Col, Input, Modal, Space, Tooltip, Tree} from "antd";
import {ClearOutlined, CloseOutlined, DownOutlined, EllipsisOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {getMetricsParentKey, loopSearch, prepareData} from "../../../../../helpers/utils/reports";
import {ReactSortable} from "react-sortablejs";
import React, {useState} from "react";
import {listKeysJsonUniversal} from "../../../../../shared/common/constants/metrics";
import CreateMetricsModal, {staticMetrics} from "../../../../../shared/common/components/reports/CreateMetricsModal";
import MetricItem from "../../../../../shared/common/components/reports/MetricItem";
import {unApi} from "../../../../../shared/common/api/endpoints/reports/unApi";
import {showErrorMessage, showSuccessMessage} from "../../../../../helpers/utils/ui";
import EditMetricsModal from "../../../../../shared/common/components/reports/EditMetricsModal";
type Props = {
    isVisible:boolean,
    setVisible:(value:boolean)=>void
    title:string
    selected:any[]
    handleListChange:(newState:any[])=>void
    setSelected:React.Dispatch<React.SetStateAction<any[]>>
    dataItems:any[]
    listKeys:any[]
}
const ModalSettings = ({isVisible,setVisible,title,selected,handleListChange,setSelected,dataItems,listKeys}:Props) => {

    const [ searchValue, setSearchValue ] = useState("")
    const [ isCreateMetricsVisible,setIsCreateMetricsVisible ] = useState(false)

    const [ editMetricsState,setEditMetricsState ] = useState<{ visible:boolean,id:null | number,type:string| null }>({
        visible:false,
        id:null,
        type:null
    })
    const [ autoExpandParent, setAutoExpandParent ] = useState(true)
    const [checkedKeys,setCheckedKeys] = useState(selected?.map((el)=>el.key))
    const [ data, setData ] = useState(dataItems)
    const [expandedKeys,setExpandedKeys] = useState([])
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        if (value !== "") {
            const reg = new RegExp(value, "i")
            const _expandedKeys: any = listKeys
                .map((item: any) => {
                    if (item.name.search(reg) > -1) {
                        return getMetricsParentKey(item.key, dataItems)
                    }
                    return null
                })
                .filter((item: any, i: number, self: any) => item && self.indexOf(item) === i)
            setExpandedKeys(_expandedKeys)
            setSearchValue(value)
            setAutoExpandParent(true)
        } else {
            setExpandedKeys([])
            setSearchValue("")
            setAutoExpandParent(false)
        }
    }
    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    }
    const onCheck = (_checkedKeys: any, e: any) => {
        setCheckedKeys(_checkedKeys)
        if (_checkedKeys.length > 0) {
            if(e.checked){
                setSelected((prev)=>[...prev,e.node])
            }else{
                setSelected((prev)=>prev.filter((el:{key:string})=>el.key !== e.node.key))
            }
        }
    }
    const handleRemove = (key:string) => {
        setSelected((prev)=>prev.filter((el:{key:string})=>el.key !== key))
        setCheckedKeys((prev)=>prev.filter((el)=>el !== key))
    }
    const confirmCreatedMetricsRemoving = async (nodeData:any) => {
        //const newCreatedMetrics = structuredClone(createdMetrics)
       // newCreatedMetrics[0].children = newCreatedMetrics[0].children.filter((el:any)=>el.name !== nodeData.name)
        try{
            await unApi.removeCustomMetric(nodeData.id,location.pathname)
            const removeMetric = (prev:any) => {
                const updatedData = [...prev]
                const newCreatedMetrics = updatedData[updatedData.length - 1].children
                updatedData[updatedData.length - 1].children = newCreatedMetrics.filter((el:any)=>el.dataKey !== nodeData.dataKey)
                return updatedData
            }
            setData(removeMetric)
            setSelected((prev:any)=>{
                return prev.filter((el:any)=>el.dataKey !== nodeData.dataKey)
            })
            // if(selectedSchool?.id){
            //     setTimeout(()=>getCreatedMetrics(selectedSchool.id),150)
            // }
            setEditMetricsState({ id:null,visible:false,type:null })
            showSuccessMessage("Метрика успешно удалена.")
        }catch(err){
            showErrorMessage("Произошла ошибка во время удаления метрики.")
            console.log(err)
        }
    }
    const handleEditMetric = (nodeData:any) => {
        setEditMetricsState({visible:true,id:nodeData.id,type:null})
    }
    const onCancel = () => {
        setVisible(false)
    }
    return (
        <Modal
            title={title}
            open={isVisible}
            onCancel={onCancel}
            width={"60%"}
            onOk={()=>setVisible(false)}
            okText="Применить"
            className="modal-settings"
            destroyOnClose
        >
            <div style={{ display:"flex",flexDirection:"row",columnGap:"10px" }}>

                <Col span={13}>
                    <Input
                        style={{ marginBottom: 8,marginTop:"8px" }}
                        placeholder={"Поиск " + "метрики"}
                        onChange={onChange}
                        allowClear
                    />
                    <Tree.DirectoryTree
                        onExpand={onExpand}
                        autoExpandParent={autoExpandParent}
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        expandedKeys={expandedKeys}
                        blockNode
                        checkable
                        switcherIcon={<DownOutlined />}
                        showIcon={false}
                        selectable={false}
                        treeData={loopSearch(data, searchValue)}
                        titleRender={(nodeData: any) => {
                            return nodeData.disabled ? (
                                <Tooltip
                                    placement="top"
                                    color="#fff"
                                    zIndex={9999}
                                    overlayClassName="page-title-tooltip"
                                >
                                    {nodeData.title}
                                </Tooltip>
                            ) : (
                                <>
                                    {
                                        nodeData.description ?
                                            <>
                                                {
                                                    nodeData.disableCheckbox ?
                                                        <Tooltip
                                                            placement="top"
                                                            title={<div className="page-title-tooltip-text">
                                                                Контактные
                                                                данные пользователей не доступны. Обратитесь к
                                                                администратору, чтобы получить к ним доступ.
                                                            </div>}
                                                            color="#fff"
                                                            zIndex={9999}
                                                            overlayClassName="page-title-tooltip"
                                                        >
                                                                    <span className="group-item-icon-wrapper">
                                                                        {nodeData.title}
                                                                        <span
                                                                            style={{ marginRight: 10, marginLeft: 5 }}
                                                                        >
                                                                            <Tooltip
                                                                                placement="bottom"
                                                                                title={<div className="page-title-tooltip-text"
                                                                                            dangerouslySetInnerHTML={{ __html: nodeData.description }}></div>}
                                                                                color="#fff"
                                                                                zIndex={9999}
                                                                                overlayClassName="page-title-tooltip"
                                                                            >
                                                                              <QuestionCircleOutlined
                                                                                  style={{ color: "#ccc", fontSize: 16 }}/>
                                                                            </Tooltip>
                                                                        </span>
                                                                    </span>
                                                        </Tooltip>
                                                        :
                                                        <MetricItem nodeData={nodeData} handleEditMetric={handleEditMetric} confirmCreatedMetricsRemoving={confirmCreatedMetricsRemoving} />
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    nodeData.disableCheckbox ?
                                                        <Tooltip
                                                            placement="top"
                                                            title={<div
                                                                className="page-title-tooltip-text">Контактные
                                                                данные пользователей не доступны. Обратитесь к
                                                                администратору, чтобы получить к ним доступ.</div>}
                                                            color="#fff"
                                                            zIndex={9999}
                                                            overlayClassName="page-title-tooltip"
                                                        >
                                                                <span>
                                                                    {nodeData.title}
                                                                </span>
                                                        </Tooltip>
                                                        :
                                                        <MetricItem nodeData={nodeData} handleEditMetric={handleEditMetric} confirmCreatedMetricsRemoving={confirmCreatedMetricsRemoving} />
                                                }
                                            </>
                                    }
                                </>
                            )
                        }}
                    />
                    <Space style={{ marginTop: '20px' }}>
                        {
                            title === "Метрики" &&  <Button
                                type="primary"
                                icon={<ClearOutlined />}
                                onClick={() => setIsCreateMetricsVisible(true)}
                            >
                                Создать метрику
                            </Button>
                        }
                    </Space>
                </Col>
                <Col span={11} style={{ paddingTop:"6px" }}>
                    <ReactSortable
                        list={selected.map((item: any) => ({ ...item, chosen: true }))}
                        className="modalSortable__container"
                        setList={(newState)=>{handleListChange(newState)}}
                        animation={200}
                        delayOnTouchStart={true}
                        delay={2}
                    >
                        {
                            selected.length > 0 && selected.map((item: any) => (
                                <div className="drag-item" key={item.key}>
                                        <span className="drag-handler">
                                            <EllipsisOutlined rotate={90} />
                                            <span className="drag-label" style={{ backgroundColor: item?.background ? item.background : "unset",width:"100%"}}>
                                                {item.label || item.name}
                                            </span>
                                        </span>
                                    <div onClick={() => handleRemove(item.key)} className="drag-remove">
                                        <CloseOutlined />
                                    </div>
                                </div>
                            ))
                        }
                    </ReactSortable>
                </Col>
            </div>
            { isCreateMetricsVisible && <CreateMetricsModal title={"Создание метрики в отчете по регистрациям"} setVisible={setIsCreateMetricsVisible} visible={isCreateMetricsVisible} />  }
            { editMetricsState.visible && <EditMetricsModal confirmCreatedMetricsRemoving={confirmCreatedMetricsRemoving} id={editMetricsState.id} visible={ editMetricsState.visible} setEditState={setEditMetricsState} /> }
        </Modal>
    )
}
export default ModalSettings
