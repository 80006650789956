import React from "react"

import ReactApexChart from "react-apexcharts";


const ChartWrap = (props:any) => {
    return (
        <ReactApexChart
            {...props}
        />
    )
}

export default ChartWrap
