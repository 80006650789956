import React, { FC, useState } from "react"

import { Button, DatePicker, InputNumber, Popover, Select, Space, Typography } from "antd"
import moment from "moment"

import { disabledMinutes } from "../../../../../../../helpers/utils/reports"

interface Props {
    currentDate: any,
    setCurrentDate: (value: any) => void
}

const ComparePeriod: FC<Props> = ({ currentDate, setCurrentDate }) => {
    const format = "DD.MM.YYYY"
    const startTime = { hour: 0, minute: 0, second: 0 }
    const endTime = { hour: 23, minute: 59, second: 59 }
    const [isOpenOutside,setIsOpenOutside] = useState(false)
    const [ currentDateSelector, setCurrentDateSelector ] = useState("")
    const [ nDays, setNDays ] = useState({ nStart: null, nEnd: null })
    const [ visibleDateModal, setVisibleDateModal ] = useState(false)
    const onChangeDate = (date: any, type: string) => {
        let start = currentDate.start
        let end = currentDate.end

        if (type === "start") {

            if (date.isAfter(end)) {
                start = date
                end = date
            } else {
                start = date
            }

        } else if (type === "end") {
            if (date.isAfter(start)) {
                end = date
            } else {
                start = date
                end = date
            }

        }
        setCurrentDate({ start: start, end: end, interval: "" })

    }

    const onChangeType = (value: any) => {
        let start:any = null
        let end:any = null
        let interval = ""
        setCurrentDateSelector(value)

        switch (value) {
            case "selectdates":
                setVisibleDateModal(true)
                return
            case "ndaysago":
                setIsOpenOutside(true)
                setVisibleDateModal(true)
                return
            case "today":
                start = moment().set(startTime)
                end = moment().set(endTime)
                break
            case "yesterday":
                start = moment().subtract(1, "days").set(startTime)
                end = moment().subtract(1, "days").set(endTime)
                break
            case "thisweek":
                start = moment().isoWeekday(1).set(startTime)
                end = moment().set(endTime);
                break
            case "lastweek":
                start = moment().subtract(1, "week").isoWeekday(1).set(startTime)
                end = moment().subtract(1, "week").isoWeekday(7).set(endTime)
                break
            case "thismonth":
                start = moment().startOf("month").set(startTime)
                end = moment().set(endTime)
                break
            case "lastmonth":
                start = moment().subtract(1, "month").startOf("month").set(startTime)
                end = moment().subtract(1, "month").endOf("month").set(endTime)
                break
            case "thisquart":
                start = moment().startOf("quarters").set(startTime)
                end = moment().set(endTime)
                break
            case "lastquart":
                start = moment().subtract(1, "quarters").startOf("quarters").set(startTime)
                end = moment().subtract(1, "quarters").endOf("quarters").set(startTime)
                break
            case "thisyear":
                start = moment().startOf("year").set(startTime)
                end = moment()
                break
            case "lastyear":
                start = moment().subtract(1, "year").startOf("year").set(startTime)
                end = moment().subtract(1, "year").endOf("year").set(startTime)
                break
            case "last7days":
                start = moment().subtract(6, "days").set(startTime)
                end = moment().set(endTime)
                break
            case "last14days":
                start = moment().subtract(13, "days").set(startTime)
                end = moment().set(endTime)
                break
            case "last30days":
                start = moment().subtract(29, "days").set(startTime)
                end = moment().set(endTime)
                break
            case "last365days":
                start = moment().subtract(365, "days").set(startTime)
                end = moment().set(endTime)
                break
            case "allthetime":
                start = moment().year(2000).set(startTime)
                end = moment().set(endTime)
                break
        }

        const payload = { start, end, interval }

        setCurrentDate(payload)
    }

    const onApply = () => {

        let start = currentDate.start
        let end = currentDate.end
        let interval = currentDate.interval

        const payload = { start, end, interval }

        setCurrentDate(payload)
        setVisibleDateModal(false)
    }

    const onChangeN = (type: string, value: any) => {
        let _nStart = nDays.nStart ? nDays.nStart : 0
        let _nEnd = nDays.nEnd ? nDays.nEnd : 0
        if (type === "nStart") _nStart = value
        if (type === "nEnd") _nEnd = value
        let start = moment()
        let end = moment()

        start = start.subtract(_nEnd, "days").set(startTime)
        end = end.subtract(_nStart, "days").set(endTime)

        if (start.isAfter(end)) {
            setCurrentDate({ start: end, end: start, interval: "ndaysago" })
        } else {
            setCurrentDate({ start: start, end: end, interval: "ndaysago" })
        }

        setNDays((prevVal: any) => ({
            ...prevVal,
            [type]: value
        }))
    }
    const DP = ({ value, type }: any) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <DatePicker
            value={moment(value)}
            onChange={(date) => onChangeDate(date, type)}
            format={format}
            disabledMinutes={disabledMinutes}
            allowClear={false}
            style={{ width: 120 }}
        />
    )

    return (
        <Space size="large">
            <Select
                style={{ width: "100%", minWidth: 210 }}
                value={currentDateSelector ? currentDateSelector : null}
                placeholder="выбрать период"
                onSelect={onChangeType}
            >
                <Select.Option value="selectdates">выбрать даты</Select.Option>
                <Select.Option value="today">сегодня</Select.Option>
                <Select.Option value="yesterday">вчера</Select.Option>
                <Select.Option value="thisweek">эта неделя</Select.Option>
                <Select.Option value="lastweek">прошлая неделя</Select.Option>
                <Select.Option value="thismonth">этот месяц</Select.Option>
                <Select.Option value="lastmonth">прошлый месяц</Select.Option>
                <Select.Option value="thisquart">этот квартал</Select.Option>
                <Select.Option value="lastquart">прошлый квартал</Select.Option>
                <Select.Option value="thisyear">этот год</Select.Option>
                <Select.Option value="lastyear">прошлый год</Select.Option>
                <Select.Option value="last7days">последние 7 дней</Select.Option>
                <Select.Option value="last14days">последние 14 дней</Select.Option>
                <Select.Option value="last30days">последние 30 дней</Select.Option>
                <Select.Option value="last365days">последние 365 дней</Select.Option>
                <Select.Option value="ndaysago">N дней назад</Select.Option>
            </Select>

            <Popover
                content={
                    currentDateSelector === "ndaysago" && isOpenOutside ?
                        <Space size="small" direction="vertical">
                            <Space>
                                <Typography.Text type="secondary">от</Typography.Text>
                                <InputNumber value={nDays.nStart} onChange={(val) => onChangeN("nStart", val)} style={{ width: 100 }} />

                                <Typography.Text type="secondary">до</Typography.Text>
                                <InputNumber value={nDays.nEnd} onChange={(val) => onChangeN("nEnd", val)} style={{ width: 100 }} />
                            </Space>

                            <div>
                                {moment(currentDate.start).format(format).toUpperCase()}&nbsp;&nbsp;—&nbsp;&nbsp;{moment(currentDate.end).format(format).toUpperCase()}
                            </div>

                            <Button type="primary" style={{ marginTop: 10 }} onClick={onApply}>
                                Применить
                            </Button>
                        </Space>
                        :
                        <Space size="small" direction="vertical">
                            <Space>
                                <DP value={currentDate.start} type="start" />
                                <Typography.Text type="secondary">—</Typography.Text>
                                <DP value={currentDate.end} type="end" />
                            </Space>

                            <Button type="primary" style={{ marginTop: 10 }} onClick={onApply}>
                                Применить
                            </Button>
                        </Space>
                }
                title="Период"
                open={visibleDateModal}
                onOpenChange={(visible)=>{setVisibleDateModal(visible),setIsOpenOutside(false)}}
                trigger="contextMenu"
                placement="bottom"
                zIndex={999}
            >
                <div>
                    {moment(currentDate.start).format(format).toUpperCase()}&nbsp;&nbsp;—&nbsp;&nbsp;{moment(currentDate.end).format(format).toUpperCase()}
                </div>
            </Popover>

        </Space>
    )
}

export default ComparePeriod
