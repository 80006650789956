import React, {Dispatch, ReactElement, SetStateAction, useEffect, useState} from 'react';
import {Modal, Form, Input, Select, Space, Card, Statistic, Tree, Tooltip, Button, Col} from 'antd';
import Params from "../../Params/Params";
import {chartDetails} from "../../../../../shared/common/constants/details";
import TypeIcon from "../../../../../shared/common/components/reports/params/attribution/icons/TypeIcon";
import FirstRegIcon from "../../../../../shared/common/components/reports/params/attribution/icons/FirstRegIcon";
import LastRegIcon from "../../../../../shared/common/components/reports/params/attribution/icons/LastRegIcon";
import UniversalChart from "../../../../../shared/common/components/reports/un-chart/Chart";
import AdChart from "../../../../../shared/common/components/reports/ad-chart/Chart";
import OrderChart from "../../../../../shared/common/components/reports/order-chart/Chart";
import PaymentChart from "../../../../../shared/common/components/reports/payment-chart/Chart";
import WebinarChart from "../../../../../shared/common/components/reports/web-chart/Chart";
import VisitsChart from "../../../../../shared/common/components/reports/ad-chart/Chart";
import {CloseOutlined, DownOutlined, EllipsisOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {
    generateListKeys,
    getMetricsParentKey,
    loopSearch,
    prepareData,
    prepareGroupsData
} from "../../../../../helpers/utils/reports";
import {
    calculateWidth,
    convertToGridUnits,
    measureTextDimensions,
    showErrorMessage
} from "../../../../../helpers/utils/ui";
import {listKeysJsonUniversal} from "../../../../../shared/common/constants/metrics";
import {StaticGroupings, staticMetrics} from '../../../../../shared/common/components/reports/CreateMetricsModal';
import {ReactSortable} from "react-sortablejs";
import {useActions} from "../../../../../shared/common/hooks/useActions";
import {defaultGroupingsAd} from "../../../../ad-reports/ADReports";
import {defaultGroupings, tableSliceActions} from "../../../../../app/providers/redux/slices/reports/table/tableSlice";
import {defaultGroupingsOrder} from "../../../../order-reports/OrderReports";
import {defaultGroupingsPayment} from "../../../../payment-reports/PaymentReports";
import {defaultGroupingsWebinar} from "../../../../webinar-reports/WebinarReports";
import {defaultGroupingsVisits} from "../../../../reports/visits/Visits";
import {DashboardComponent} from "../../../Dashboards";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import Table from '../../../../../shared/common/components/reports/dashboards/Table';
import {removeCookie, setCookie} from '../../../../../helpers/utils/cookies';
import ButtonsType from '../../ButtonsType';
import {useTypedSelector} from "../../../../../shared/common/hooks/useTypedSelector";
import ModalSettings from "../ModalSettings";
import {MetricsPaths} from "../../../../../helpers/utils/enums";
import {unApi} from "../../../../../shared/common/api/endpoints/reports/unApi";
import {handleResponseError} from "../../../../../shared/common/api/middleware";
import {normalizeCreatedMetrics} from "../../../../../helpers/utils/functions";

interface Props {
    editState: {
        visible:boolean,
        id:string,
        data:any
    };
    setEditState:React.Dispatch<SetStateAction<{ visible: boolean; id: string; data: {}; }>>
    onEditBlock:(component:DashboardComponent)=>void
}
const dateObjects = [
    {
        value:"order",
        content:"Дата заказа"
    },
    {
        value:"reg",
        content:"Дата регистрации"
    },
    {
        value:"ad",
        content:"Дата рекламы"
    },
    {
        value:"web",
        content:"Дата вебинара"
    },
    {
        value:"payment",
        content:"Дата платежа"
    },
    {
        value:"visit",
        content:"Дата посещения страницы"
    }
]
const displayObjects = [
    {
        value:"number",
        content:"Число"
    },
    {
        value:"chart",
        content:"Диаграмма"
    },
    {
        value:"table",
        content:"Таблица"
    },
    {
        value:"heading",
        content:"Заголовок"
    }
]
const chartsComponents:{[key:string]:ReactElement} = {
    reg:<UniversalChart />,
    ad:<AdChart />,
    web:<WebinarChart />,
    payment:<PaymentChart />,
    visit:<VisitsChart />,
    order:<OrderChart />
}
const attrItems = [
    {
        id: "type_applications",
        name: "Тип регистрации:",
        selectValues: [
            { name: "Нулевые заказы GC", id: "2", icon: null },
            { name: "Регистрации GC", id: "0", icon: null },
            { name: "SCAN", id: "1", icon: null },
            //{ name: "Ручное добавление", id: "3", icon: null },
        ],
        description: `Выбор типа регистрации по которым нужно создать отчёт:
                    <br><br>
                    Типы регистраци:
                    <br><br>
                    - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                    - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                    - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
        icon: <TypeIcon />,
    },
    {
        id: "attribution",
        name: "Атрибуция:",
        selectValues: [
            { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
            { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
        ],
        description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                    <br><br>
                    - Первая регистрация <br>
                    - Последняя регистрация <br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
        icon: null,
    },
]
const EditBlockModal: React.FC<Props> = ({ editState,setEditState,onEditBlock}) => {
    const { details } = useTypedSelector((state) => state.details)
    const { filters } = useTypedSelector((state) =>  editState.data.data.blockId ? state.filter.blocks[editState.data.data.blockId] : state.filter)
    const { attributes } = useTypedSelector((state) => state.attributes)
    const {
        groupings,
        metrics,
        metricsChecked,
        groupsChecked,
        totalChecked,
        percentages,
        filtersTable
    } = useTypedSelector((state) => editState.data.data.blockId ? state.table.blocks[editState.data.data.blockId] : state.table)
    const { chartType } = useTypedSelector((state) => editState.data.data.blockId ? state.chart.blocks[editState.data.data.blockId] : state.chart)
    const [heading,setHeading] = useState(editState.data.data.heading || '')
    const { setGroupings,
        setAttributesDashboardBlocks,
        setDashboardBlockDetails,
        setMetrics,
        setMetricsChecked,
        setAllDataFetching,
        setDashboardsBlocksInitialState,
        setDashboardsBlocksTableInitialState,
        setDashboardsBlocksChartState,
        setGroupingsChecked,
        setAttributes,
        setDetails,
        setFilters,
        setPercentages
    } = useActions()
    const [form] = Form.useForm();
    const [ currentSelect,setCurrentSelect ] = useState(editState.data.componentType)
    const [ isBasicMetricsVisible,setIsBasicMetricsVisible ] = useState(false)
    const [ isBasicGroupingsVisible,setIsBasicGroupingsVisible ] = useState(false)
    const [selectedMetrics,setSelectedMetrics] = useState<any[]>(editState.data.data.selectedMetrics)
    const [selectedGroupings,setSelectedGroupings] = useState<any[]>(editState.data.data.selectedGroupings)
    const { selectedSchool } = useTypedSelector(
        (state) => state.currentSchool
    )
    const getStaticMetricDataItems = (pathname:string,additionalMetrics=[]) => prepareData(
        [ ...staticMetrics[pathname as keyof typeof staticMetrics],... [{
            name: "Созданные метрики",
            children: additionalMetrics
        }]],
        "",
        [],
        false
    )

    const getStaticMetricDataListKeys = (pathname:string) => JSON.parse(listKeysJsonUniversal[pathname as keyof typeof listKeysJsonUniversal])
    const [dataItemsMetrics,setDataItemsMetrics] =  useState([])
    const [listKeysMetrics,setListKeysMetrics] = useState([])
    const [dataItemsGroupings,setDataItemsGroupings] =  useState([])
    const [listKeysGroupings,setListKeysGroupings] = useState([])
    const renderView = () => {
        let previewContent
        switch (currentSelect) {
            case 'number':
                previewContent =  (
                    <Card style={{  borderRadius:"10px" }} bodyStyle={{ padding: "10px 20px", width: 200 }} className={"bordered-card"}>
                        <Statistic
                            title={
                                <div style={{ display:"flex",flexDirection:"column",alignItems:"center" }}>
                                    <span style={{ color:"black" }}>Клики</span>
                                    <span style={{ color:"black",fontSize:"16px",fontWeight:"500" }}>30 370</span>
                                </div>
                            }
                            prefix={"+"}
                            suffix={"%"}
                            value={"72,43"}
                            valueStyle={{ color:"#47d347",fontSize:'12px' }}
                            precision={0}
                        />
                    </Card>
                )
                break;
            case 'chart':
                const dateValue = form.getFieldValue("date")
                previewContent = (
                    <div className={"table-modal"} style={{ background:"white",padding:"10px" }}>
                        {dateValue ? chartsComponents[dateValue] : <UniversalChart />}
                    </div> )
                break;
            case 'table':
                previewContent = ( <div className={"table-modal"} style={{ overflowX:"auto",paddingRight:"20px" }}>
                    <Table />
                </div> )
                break;
            case 'heading':
                previewContent = ( <div className={"table-modal"} style={{ overflowX:"auto",paddingRight:"20px" }}>
                    <p>{heading}</p>
                </div> )
                break;
            default:
                previewContent = <div>Выберите тип</div>;
        }

        return previewContent;
    }
    const handleCloseModal = () => {
        setEditState({
            id:"",
            visible:false,
            data:{}
        })
    }
    const handleListChange = (newList:any[],fn1:Dispatch<SetStateAction<any[]>>,fn2:(newList:any)=>{}) => {
        fn1(newList)
        fn2({data:newList})
    }

    /*
    Metrics
     */


    useEffect(()=>{
        if(currentSelect === "chart" || currentSelect === "table"){
            setMetricsChecked({data:selectedMetrics})
            setMetrics({data:selectedMetrics})
            setGroupings({data:selectedGroupings})
        }
    },[selectedMetrics,selectedGroupings])
    const handlePeriodDateChange = async (value:string,isInitial=false) => {
        const getCreatedMetrics = async (pathname:string) => {
            if(selectedSchool?.id){
                return await unApi.getCustomMetrics(selectedSchool?.id,pathname).then((res) => {
                    return normalizeCreatedMetrics(res.data)
                })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
        const createdMetrics = await getCreatedMetrics("/users/universal")

        switch (value){
            case "reg":{
                setGroupings({data:[]})
                setMetrics({data:[]})
                setSelectedMetrics([])
                setSelectedGroupings([])
                setDataItemsMetrics(getStaticMetricDataItems(MetricsPaths.Universal,await getCreatedMetrics(MetricsPaths.Universal) as []))
                setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.Universal))
                setDataItemsGroupings(prepareGroupsData(StaticGroupings[MetricsPaths.Universal]))
                setListKeysGroupings(generateListKeys(prepareGroupsData(StaticGroupings[MetricsPaths.Universal])))
                break
            }
            case "ad": {
                setSelectedMetrics([])
                setSelectedGroupings([])
                setGroupings({data:[]})
                setMetrics({data:[]})
                setDataItemsMetrics(getStaticMetricDataItems(MetricsPaths.AdReports,await getCreatedMetrics(MetricsPaths.AdReports) as []))
                setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.AdReports))
                setDataItemsGroupings(prepareGroupsData(StaticGroupings[MetricsPaths.AdReports]))
                setListKeysGroupings(generateListKeys(prepareGroupsData(StaticGroupings[MetricsPaths.AdReports])))
                break
            }
            case "order":{
                setSelectedMetrics([])
                setSelectedGroupings([])
                setGroupings({data:[]})
                setMetrics({data:[]})
                setDataItemsMetrics(getStaticMetricDataItems(MetricsPaths.OrderReports,await getCreatedMetrics(MetricsPaths.OrderReports) as []))
                setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.OrderReports))
                setDataItemsGroupings(prepareGroupsData(StaticGroupings[MetricsPaths.OrderReports]))
                setListKeysGroupings(generateListKeys(prepareGroupsData(StaticGroupings[MetricsPaths.OrderReports])))
                break
            }
            case "payment":{
                setSelectedMetrics([])
                setSelectedGroupings([])
                setGroupings({data:[]})
                setMetrics({data:[]})
                setDataItemsMetrics(getStaticMetricDataItems(MetricsPaths.PaymentReports,await getCreatedMetrics(MetricsPaths.PaymentReports) as []))
                setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.PaymentReports))
                setDataItemsGroupings(prepareGroupsData(StaticGroupings[MetricsPaths.PaymentReports]))
                setListKeysGroupings(generateListKeys(prepareGroupsData(StaticGroupings[MetricsPaths.PaymentReports])))
                break
            }
            case "web":{
                setSelectedMetrics([])
                setSelectedGroupings([])
                setGroupings({data:[]})
                setMetrics({data:[]})
                setDataItemsMetrics(getStaticMetricDataItems(MetricsPaths.WebinarReports,await getCreatedMetrics(MetricsPaths.WebinarReports) as []))
                setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.WebinarReports))
                setDataItemsGroupings(prepareGroupsData(StaticGroupings[MetricsPaths.WebinarReports]))
                setListKeysGroupings(generateListKeys(prepareGroupsData(StaticGroupings[MetricsPaths.WebinarReports])))
                break
            }
            case "visit":{
                setSelectedMetrics([])
                setSelectedGroupings([])
                setGroupings({data:[]})
                setMetrics({data:[]})
                setDataItemsMetrics(getStaticMetricDataItems(MetricsPaths.Universal,await getCreatedMetrics(MetricsPaths.Universal) as []))
                setListKeysMetrics(getStaticMetricDataListKeys(MetricsPaths.Universal))
                setDataItemsGroupings(prepareGroupsData(StaticGroupings[MetricsPaths.Universal]))
                setListKeysGroupings(generateListKeys(prepareGroupsData(StaticGroupings[MetricsPaths.Universal])))
                break
            }
        }
        if(isInitial){
            setSelectedGroupings(editState.data.data.selectedGroupings)
            setSelectedMetrics(editState.data.data.selectedMetrics)
        }

        setAllDataFetching({value:true})
    }
    const onSubmit = (values:any) => {
        const newBlockId = editState.data.data.blockId
        const attributionValue  = typeof attributes?.attribution === "string"
            ? [ attributes.attribution ]
            : attributes?.attribution
        const typeApplicationsValue =  typeof attributes?.type_applications === "string"
            ? [ attributes.type_applications ]
            : attributes?.type_applications
        const chartData = {
            details,
            filters: filters,
            attribution: {
                attribution: attributionValue,
                type_applications: typeApplicationsValue,
            },
            // groupings_names,
            // selected_groups_path,
            // selected_metrics_names,
        }
        const isChosenNumberMetric = currentSelect === "number"
        if(isChosenNumberMetric && metricsChecked.length === 0){
            showErrorMessage("Выберите метрику в таблице.")
            return
        }

        setAttributesDashboardBlocks({
            blockId:newBlockId,
            attribution: attributes?.attribution,
            type_applications: attributes?.type_applications
        })

        setDashboardsBlocksInitialState([newBlockId])
        setDashboardsBlocksTableInitialState({ids:[newBlockId],data:{
                groupings,metrics,metricsChecked,groupsChecked,totalChecked,percentages,filtersTable
            }})
        setFilters({data:filters,blockId:newBlockId})

        setDashboardsBlocksChartState({ids:[newBlockId],data:{
                chartType,

            }})
        setDashboardBlockDetails({
            blockId:newBlockId,
            detail:details
        })
        console.log(values,selectedMetrics,chartData)

        const newComponent: DashboardComponent = {
            title: values.title,
            componentType: currentSelect,
            layoutData: {
                i: newBlockId,
                x: 0,
                y: Infinity,
                ...(() => {
                    if (editState?.data?.componentType === "heading") {
                        const textDimensions = measureTextDimensions(values.title);
                        const { w, h } = convertToGridUnits(textDimensions.width, textDimensions.height, 140, 30);
                        return { w, h };
                    } else {
                        return {
                            w: calculateWidth(selectedMetrics.length, 12),
                            h: 12
                        };
                    }
                })(),
            },
            data: {
                blockId:newBlockId,
                selectedMetrics,
                selectedGroupings,
                heading,
                ...values,
                date:values.date || editState.data.data.date,
                ...chartData
            }
        }
        console.log(newComponent)
        onEditBlock(newComponent)
        handleCloseModal()
    }

    useEffect(()=>{
        setCookie("chosenDisplay",editState.data.componentType,{expires:300})
        setAttributes(editState.data.data.attribution)
        setDetails(editState.data.data.details)
        handlePeriodDateChange(editState.data.data.date,true)
        return () => {
            setGroupings({data:[]})
            setPercentages([])
            setMetrics({data:[]})
            setGroupingsChecked([])
            setMetricsChecked({data:[]})
        }
    },[])
    console.log(editState,selectedMetrics,selectedGroupings)

    return (
        <Modal
            title="Редактирование блока"
            open={editState.visible}
            className={"addBlockModal modal-settings"}
            width={"78%"}
            footer={null}
            maskClosable={false}
            onCancel={handleCloseModal}
        >
            <div style={{ display: 'flex',flexDirection:"column" }}>
                <div style={{ width:"100%", marginRight: '24px' }}>
                    <Form onFinish={onSubmit} layout={"vertical"} onFieldsChange={(changedFields)=>{
                        if(changedFields[0].name[0] === "display"){
                            setCookie("chosenDisplay",changedFields[0].value,{expires:300})
                            setCurrentSelect(changedFields[0].value)
                        }
                    }} form={form}>
                        <div style={{ marginTop:"16px",width:"100%",display:'flex',columnGap:"8px" }}>
                            <Form.Item initialValue={editState.data.title} name="title" label="Название блока">
                                {
                                    heading ?
                                        <Input
                                            value={heading}
                                            onChange={(event)=>setHeading(event.target.value)}
                                        />
                                        : <Input />
                                }
                            </Form.Item>
                            <Form.Item initialValue={editState.data.data.description} style={{ width:"60%" }} name="description" label="Описание">
                                <Input />
                            </Form.Item>
                        </div>
                        {
                            !heading && <>
                                <div style={{display: "flex", columnGap: "20px"}}>
                                    <Form.Item style={{minWidth: "310px"}} name="date" label="Дата периода">
                                        <Select defaultValue={editState.data.data.date}
                                                onSelect={(value) => handlePeriodDateChange(value)}>
                                            {
                                                dateObjects.map((el) => (
                                                    <Select.Option key={el.value}
                                                                   value={el.value}>{el.content}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{minWidth: `${form.getFieldValue("display") === "chart" ? 240 : 410}px`}} name="display" label="Что показываем?">
                                        <Select defaultValue={currentSelect}>
                                            {
                                                displayObjects.map((el) => (
                                                    <Select.Option key={el.value}
                                                                   value={el.value}>{el.content}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    {
                                        (form.getFieldValue("display") === "chart" || form.getFieldValue("display") === "table") &&
                                        <>
                                            {
                                                form.getFieldValue("display") === "chart" &&
                                                <div style={{display: "flex", flexDirection: "column", rowGap: "8px"}}>
                                                    <span>Тип графика</span>
                                                    <ButtonsType />
                                                </div>
                                            }


                                        </>
                                    }
                                </div>

                            </>
                        }

                        {
                            currentSelect !== "" && currentSelect !== "heading" && <div style={{marginBottom: "20px"}}>
                                <Params
                                    attributionItems={attrItems}
                                    detailsItems={chartDetails}
                                    filtersFilter={[
                                        "Пользователи",
                                        "Регистрации",
                                        "Шаги воронки",
                                        "Заказы",
                                        "Платежи",
                                        "Вебинары",
                                    ]}
                                />
                            </div>

                        }

                        {
                            currentSelect === "heading" ?
                               null
                                : (
                                    <>
                                        <div className="universal-table-settings" style={{ display:"flex",justifyContent:"space-between" }}>
                                            <div style={{ display:"flex",columnGap:"4px" }}>
                                                <Button onClick={()=>setIsBasicGroupingsVisible(true)}>Группировки</Button>
                                                <Tooltip
                                                    title={
                                                        <div
                                                            className="page-title-tooltip-text"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    "Группировка — это возможность сгруппировать регистрации пользователей по определённым условиям и посмотреть их данные по 60 метрикам. В SCANе около 70 различных группировок. <a href=\"https://help.scananalytics.ru/?p=634\" rel=\"noopener noreferrer\" target=\"_blank\">Подробнее</a>.",
                                                            }}
                                                        ></div>
                                                    }
                                                    color="#fff"
                                                    zIndex={9999}
                                                    trigger="click"
                                                    overlayClassName="page-title-tooltip"
                                                >
                                                    <QuestionCircleOutlined
                                                        style={{ color: "#ccc", fontSize: 14, marginLeft: 10 }}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <div style={{ display:"flex",columnGap:"20px" }}>
                                                <Button onClick={()=>setIsBasicMetricsVisible(true)}>Метрики</Button>
                                                <Tooltip
                                                    placement="bottom"
                                                    title={
                                                        <div
                                                            className="page-title-tooltip-text"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    "Метрики — это показатель выбранных группировок. В сервисе около 60 разных показателей. <a href=\"https://help.scananalytics.ru/?p=636\" rel=\"noopener noreferrer\" target=\"_blank\">Подробнее<a/>.",
                                                            }}
                                                        ></div>
                                                    }
                                                    color="#fff"
                                                    zIndex={9999}
                                                    trigger="click"
                                                    overlayClassName="page-title-tooltip"
                                                >
                                                    <QuestionCircleOutlined
                                                        style={{ color: "#ccc", fontSize: 14 }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className={"table-modal"} style={{ overflowX:"auto",paddingRight:"20px" }}>
                                            <Table />
                                        </div>
                                    </>
                                )
                        }
                    </Form>
                </div>
                <div style={{ width:"100%",...(currentSelect === "chart" ? { paddingLeft:"4%",paddingRight:"4%" } : { display: 'flex' }), alignItems: 'center', justifyContent: 'center',backgroundColor:"#D0D0D0",borderTop:"1px solid black",marginLeft:"-25px",flex:1,paddingTop:"4%" }}>
                    <div style={{ textAlign: 'center',...(currentSelect === "table" ? { width:"90%" }  : {}) }}>
                        <Form.Item>
                            {
                                renderView()
                            }
                        </Form.Item>
                    </div>
                </div>
                <div style={{ marginTop:"20px" }}>
                    <Button type="primary" style={{ marginBottom:"10px" }} onClick={()=>form.submit()} htmlType="submit">
                        Сохранить
                    </Button>
                </div>
            </div>
            {
                isBasicMetricsVisible &&  <ModalSettings dataItems={dataItemsMetrics} listKeys={listKeysMetrics} isVisible={isBasicMetricsVisible} setVisible={(value)=>{setIsBasicMetricsVisible(value),setAllDataFetching({value:true})}} title={"Метрики"} selected={selectedMetrics} handleListChange={(newState)=>{handleListChange(newState,setSelectedMetrics,setMetrics)}} setSelected={setSelectedMetrics} />
            }
            {
                isBasicGroupingsVisible &&  <ModalSettings dataItems={dataItemsGroupings} listKeys={listKeysGroupings} isVisible={isBasicGroupingsVisible} setVisible={(value)=>{setIsBasicGroupingsVisible(value),setAllDataFetching({value:true})}} title={"Группировки"} selected={selectedGroupings} handleListChange={(newState)=>{handleListChange(newState,setSelectedGroupings,setGroupings)}} setSelected={setSelectedGroupings} />
            }
        </Modal>
    )
}

export default EditBlockModal
