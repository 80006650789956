import React, {FC, ReactNode} from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

interface ILoaderProps {
    loading: boolean
    hasTip?: boolean
    children?:ReactNode
}

const Loader = ({ loading, hasTip = false, children }:ILoaderProps) => {
    return (
        <Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            tip={hasTip ? "Загрузка..." : ""}
            style={{...(location.pathname.includes("dashboard") ? {height:"100%"} : {})}}
        >
            {children}
        </Spin>
    )
}

export default Loader
