import React, { FC, useEffect, useState } from "react"

import { DownOutlined, CloseOutlined, EllipsisOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import {Tree, Input, Modal, Row, Col, Button, Tooltip, Space} from "antd"
import { ReactSortable } from "react-sortablejs"
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useForceUpdate} from "../../../hooks/useForceUpdate";
import {useActions} from "../../../hooks/useActions";
import {
    filterGroupsDisabledData,
    getChildrenKey,
    getMetricsParentKey,
    loopSearch
} from "../../../../../helpers/utils/reports";
import {ads_keys} from "../Groupings";
import {ads_metrics_keys} from "../Metrics";
import CreateMetricsModal from "../CreateMetricsModal";
import {showErrorMessage} from "../../../../../helpers/utils/ui";



function ClearOutlined() {
    return null
}

const ModalSettings: FC<any> = ({  dataItems, listKeys, defSelected, defChecked, actionSet, isDisable, hidePersonalDataGroups = false,selectedProps,setSelectedProps }) => {
    const forceUpdate = useForceUpdate()
    const { groupings, metrics, metrics_filters_update } = useTypedSelector((state) => state.table)
    const { setMetricsFiltersUpdate,setIsNotParamsFilterVisible } = useActions()
    const [ isModalVisible, setIsModalVisible ] = useState(false)
    const [ data, setData ] = useState(dataItems)
    const [ selected, setSelected ] = useState<any>(selectedProps)
    const [ checkedKeys, setCheckedKeys ] = useState<any>([])
    const [ expandedKeys, setExpandedKeys ] = useState<any>([])
    const [ searchValue, setSearchValue ] = useState("")
    const [ autoExpandParent, setAutoExpandParent ] = useState(true)
    const [ adsData, setAdsData ] = useState([])
    const [ notAdsData, setNotAdsData ] = useState([])
    const userRole = useTypedSelector((state) => state.currentUser.role)
    const [isDisabledSelected,setisDisabledSelected] = useState(false)
    const [ isBasicMetricsVisible,setIsBasicMetricsVisible ] = useState(false)


    useEffect(() => {

        if (hidePersonalDataGroups) {
            setData(filterGroupsDisabledData(data, [ "user_id", "user_email", "user_id_ext" ]))
        } else {
            setData(filterGroupsDisabledData(data, [ "" ]))
        }
    }, [ hidePersonalDataGroups, data, dataItems ])

    if (userRole === "user") {
        hidePersonalDataGroups = true
    }

    useEffect(() => {
        !isDisabledSelected && setSelected(defSelected)
    }, [defSelected])

    useEffect(() => {
        !isDisabledSelected && setCheckedKeys(defChecked)
    }, [defChecked])

    const removeSelect = (key: string) => {
        setSelected((prev: any) => {
            return [ ...prev ].filter((item: any) => item.key !== key)
        })

        setCheckedKeys((prev: any) => {
            return [ ...prev ].filter((item) => item !== key)
        })
    }

    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    }
    const onCheck = (_checkedKeys: any, e: any) => {
        setCheckedKeys(_checkedKeys.slice(-1)[0])
        setSelected(() => {
            let newData: any = []
            if (e.checked) {
                newData.push({ ...e.node })
            }
            setSelectedProps(newData)
            return newData
        })
        _checkedKeys.length > 0 && setIsBasicMetricsVisible(false)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        if (value !== "") {
            const reg = new RegExp(value, "i")

            let _expandedKeys: any = listKeys
                .map((item: any) => {
                    if (item.name.search(reg) > -1) {
                        return getMetricsParentKey(item.key, dataItems)
                    }
                    return null
                })
                .filter((item: any, i: number, self: any) => item && self.indexOf(item) === i)

            setExpandedKeys(_expandedKeys)
            setSearchValue(value)
            setAutoExpandParent(true)
        } else {
            setExpandedKeys([])
            setSearchValue("")
            setAutoExpandParent(false)
        }
    }

    const handleOk = () => {
        actionSet(selected)
        setIsModalVisible(false)
        setExpandedKeys([])
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const clearCheckboxes = () => {
        setSelected([])
        setCheckedKeys([])
        !isDisabledSelected && setisDisabledSelected(true)
        forceUpdate()
    }
    const calculateWidth = () => {
        const defaultWidth = 150
        const characterCount = selectedProps[0]?.name.length || 0
        const calculatedWidth = (characterCount * 8) + 35
        return Math.min(calculatedWidth === 35 ? defaultWidth :  calculatedWidth, 1000)
    };

    return (
        <div className="modal-settings">
            <Row gutter={12}>
                <Col span={10} style={{ marginTop:"24px" }}>
                    <span style={{ fontWeight:"700" }}>Выберите метрику</span>
                    <div style={{ display:"flex",flexDirection:"column",marginTop:"12px" }}>
                        <Space>
                            <Input value={selectedProps[0]?.name} style={{ width:calculateWidth() }} onClick={()=>setIsBasicMetricsVisible(true)} placeholder={"Выбор метрики"} />
                        </Space>
                    </div>
                    <Modal
                        title={"Метрики"}
                        open={isBasicMetricsVisible}
                        onCancel={()=>setIsBasicMetricsVisible(false)}
                        width={800}
                        className="modal-settings"
                        destroyOnClose
                        footer={null}
                    >
                    <div style={{ overflow: "auto" }}>
                        <Input
                            style={{ marginBottom: 8,marginTop:"8px" }}
                            placeholder={"Поиск " + "метрики"}
                            onChange={onChange}
                            allowClear
                        />
                        <Tree.DirectoryTree
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            blockNode
                            checkable
                            switcherIcon={<DownOutlined />}
                            showIcon={false}
                            selectable={false}
                            treeData={loopSearch(data, searchValue)}
                            titleRender={(nodeData: any) => {
                                return nodeData.disabled ? (
                                    <Tooltip
                                        placement="top"
                                        color="#fff"
                                        zIndex={9999}
                                        overlayClassName="page-title-tooltip"
                                    >
                                        {nodeData.title}
                                    </Tooltip>
                                ) : (
                                    <>
                                        {
                                            nodeData.description ?
                                                <>
                                                    {
                                                        nodeData.disableCheckbox ?
                                                            <Tooltip
                                                                placement="top"
                                                                title={<div className="page-title-tooltip-text">
                                                                    Контактные
                                                                    данные пользователей не доступны. Обратитесь к
                                                                    администратору, чтобы получить к ним доступ.
                                                                </div>}
                                                                color="#fff"
                                                                zIndex={9999}
                                                                overlayClassName="page-title-tooltip"
                                                            >
                                                                    <span className="group-item-icon-wrapper">
                                                                        {nodeData.title}
                                                                        <span
                                                                            style={{ marginRight: 10, marginLeft: 5 }}
                                                                        >
                                                                            <Tooltip
                                                                                placement="bottom"
                                                                                title={<div className="page-title-tooltip-text"
                                                                                            dangerouslySetInnerHTML={{ __html: nodeData.description }}></div>}
                                                                                color="#fff"
                                                                                zIndex={9999}
                                                                                overlayClassName="page-title-tooltip"
                                                                            >
                                                                              <QuestionCircleOutlined
                                                                                  style={{ color: "#ccc", fontSize: 16 }}/>
                                                                            </Tooltip>
                                                                        </span>
                                                                    </span>
                                                            </Tooltip>
                                                            :
                                                            <span className="group-item-icon-wrapper">
                                                                    {nodeData.title}
                                                                <span
                                                                    style={{ marginRight: 10, marginLeft: 5 }}
                                                                >
                                                                        <Tooltip
                                                                            placement="bottom"
                                                                            title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: nodeData.description }}></div>}
                                                                            color="#fff"
                                                                            zIndex={9999}
                                                                            overlayClassName="page-title-tooltip"
                                                                        >
                                                                        <QuestionCircleOutlined
                                                                            style={{ color: "#ccc", fontSize: 16 }}/>
                                                                        </Tooltip>
                                                                    </span>
                                                                </span>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        nodeData.disableCheckbox ?
                                                            <Tooltip
                                                                placement="top"
                                                                title={<div
                                                                    className="page-title-tooltip-text">Контактные
                                                                    данные пользователей не доступны. Обратитесь к
                                                                    администратору, чтобы получить к ним доступ.</div>}
                                                                color="#fff"
                                                                zIndex={9999}
                                                                overlayClassName="page-title-tooltip"
                                                            >
                                                                <span>
                                                                    {nodeData.title}
                                                                </span>
                                                            </Tooltip>
                                                            :
                                                            <span>
                                                                {nodeData.title}
                                                            </span>
                                                    }
                                                </>
                                        }
                                    </>
                                )
                            }}
                        />
                    </div>
                    </Modal>
                </Col>
            </Row>

        </div>
    )
}

export default ModalSettings
