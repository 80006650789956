import React, {FC, ReactNode} from "react";

import { Navigate } from "react-router-dom";

import { routeNames } from "../constants/routes";
import { useTypedSelector } from "../hooks/useTypedSelector";

interface IProps {
  allowedRoles: string[];
  redirectToErrPage?: boolean;
  returnedElement?: React.ReactElement | null;
  children?:ReactNode
}

const PermissionWrapper = ({
  allowedRoles,
  redirectToErrPage = true,
  returnedElement = null,
  children,
}:IProps) => {
  const userRole = useTypedSelector((state) => state.currentUser.role);
  const acceptedRoles = ["superadmin", ...allowedRoles];

  return userRole ? (
    acceptedRoles.includes(userRole) ? (
      <>{children}</>
    ) : redirectToErrPage ? (
      <Navigate to={routeNames.accessEnded} replace />
    ) : (
      returnedElement
    )
  ) : null;
};

export default PermissionWrapper;
