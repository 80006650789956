import React, { ReactNode, useEffect, useRef, useState} from "react";

import { useNavigate } from "react-router-dom";

import { routeNames } from "../../../constants/routes";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {Button, Drawer} from "antd";
import {CloseOutlined, LinkOutlined} from "@ant-design/icons";
type Props = {
    children:ReactNode
}
const GlobalModalsWrapper = ({ children }:Props) => {
    const navigate = useNavigate();
    const { isAboutReportOpen,drawerLink } = useTypedSelector((state) => state.app);
    const [visible,setVisible] = useState(isAboutReportOpen)
    const { setAppReportOpen,setDrawerLink } = useActions();
    useEffect(() => {
        if (isAboutReportOpen) {
            setVisible(isAboutReportOpen)
        }
    }, [isAboutReportOpen]);
    const onClose = () => {
        setAppReportOpen(false)
        setVisible(false)
    };
    const iframeRef = useRef(null);

    const openLink = () => {
        window.open(drawerLink, '_blank');
    }
    useEffect(() => {
        if(visible){
            const drawerBody = document.querySelector('.ant-drawer-body') as HTMLDivElement
            const drawerHeader = document.querySelector('.ant-drawer-header') as HTMLDivElement
            if (drawerBody) {
                drawerBody.style.padding = '0';
                drawerBody.style.overflow = 'hidden';
            }
            if(drawerHeader){
                drawerHeader.style.border = 'none'
                drawerHeader.style.display = 'none'
            }
            const postMessageToIframe = (data:string) => {
                const iframe = iframeRef.current
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                if (iframe && iframe.contentWindow) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    iframe.contentWindow.postMessage(data, 'https://lk.scananalytics.ru');
                }
            }
            postMessageToIframe("deleteElement");
        }
    })
    const drawerStyles = {
        header: {
            border:'none'
        },
    };
    return <>
        <Drawer
            placement="right"
            onClose={onClose}
            visible={visible}
            width={650}
            style={{ zIndex:1001 }}
            rootStyle={{ padding:0,overflow:"hidden",zIndex:1001 }}
        >
            <div style={{ position: 'absolute', top: 30, left: -40, zIndex: 1001 }}>
                <Button
                    icon={<CloseOutlined />}
                    onClick={onClose}
                    shape={"circle"}
                    style={{ display: 'block', marginBottom: 10 }}
                />
                <Button
                    icon={<LinkOutlined />}
                    onClick={openLink}
                    shape={"circle"}
                    style={{ display: 'block' }}
                />
            </div>
            <div style={{ marginTop:"10px" }}></div>
            <iframe
                style={{ border:"none" }}
                ref={iframeRef}
                src={`${drawerLink}?timestamp=${new Date().getTime()}`}
                width="100%"
                height="100%"

            />
        </Drawer>
        {children}
    </>;
};

export default GlobalModalsWrapper;
