import React, {useEffect, useRef, useState} from "react";
import {Button, Input, InputNumber, Select, Space, Switch, Tooltip} from "antd";
import { RedoOutlined } from "@ant-design/icons";
import { useTypedSelector } from "../../../../../shared/common/hooks/useTypedSelector";
import { useActions } from "../../../../../shared/common/hooks/useActions";
import { adsCabinetApi } from "../../../../../shared/common/api/endpoints/adsCabinetApi";
import TableReport from "../../../../../shared/common/components/reports/table-report/TableReport";
import { API_ADS_GET_DATA } from "../../../../../shared/common/api/urls";
import {unApi} from "../../../../../shared/common/api/endpoints/reports/unApi";
import AutoWidthSelect from "../../../../../shared/common/components/auto-width-select/AutoWidthSelect";
import {currencies} from "../../../../../helpers/utils/data";
import {splitNameAndId} from "../../../../../helpers/utils/functions";
import EditUtm from "../../connect-ads/EditUtm";

interface Props {
    refetch: boolean;
    mode: number;
    handleRenameRK: (record: { id: number }) => void;
    handleProcessingRK: (record: { id: number }) => void;
    setRefetch: (val: (prev: boolean) => boolean) => void;
}


const TableCabs = ({
                       mode,
                       handleRenameRK,
                       handleProcessingRK,
                       refetch,
                       setRefetch,
                   }: Props) => {
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
    const { cabinetData, isLoading, isUpdate } = useTypedSelector(
        (state) => state.adsCabinet
    );
    const [ utmLabelsState,setUtmLabelsState ] = useState({
        visible:false,
        id:0,
        fields:{}
    })
    const [ utmMasks,setUtmMasks] = useState<{[key:string]:{
            value: boolean,
            number: number,
            divider: string
        }}>({})
    const { isTableFetching } = useTypedSelector(state => state.meta)
    const { filters } = useTypedSelector((state) => state.filter)
    const utmMaskIds = useRef<number[]>([])

    const { setAdsCabinetData,
        setAdsCabinetLoading,
        adsCabinetUpdateRKCurrency,
        setAdsCabinetUpdate,
        setMetaTableFetching,
        adsCabinetUpdateAdCabinet,
        adsCabinetUpdateUTMStatus,
        adsCabinetUpdateUTMLabel,
        adsCabinetUpdateRKnds,
        adsCabinetUpdateRKConvert,
        adsCabinetUpdateUTMMask
    } = useActions();

    const getTableData = async () => {
        try {
            setAdsCabinetLoading(true)
            const { data } = await unApi.getRK({school_id:Number(selectedSchool?.id), status_id:mode,type:2,filters});
            setAdsCabinetData(data)
        } catch (err) {
            console.log(err);
        }finally{
            setAdsCabinetUpdate(false)
            setAdsCabinetLoading(false)
            setMetaTableFetching({value:false})
        }
    };

    useEffect(() => {
        if(isUpdate || isTableFetching){
            getTableData();
        }
    }, [isUpdate,isTableFetching]);

    useEffect(() => {
        getTableData();
    }, [mode, selectedSchool, refetch]);


    const utmMasksCellRenderer = ({ cellData, rowData }:any) => {
        if(!rowData.utm_has_id) return
        if (cellData !== null && !utmMasks[rowData.id] && !utmMaskIds.current.includes(rowData.id)) {
            utmMaskIds.current = [...utmMaskIds.current,rowData.id]
            setUtmMasks((prev) => ({ ...prev, [rowData.id]: { ...cellData, value: true } }));
        }
        return (
            <Space style={{ minWidth: '200px' }}>
                <Select
                    style={{ minWidth: '150px' }}
                    value={utmMasks[rowData.id]?.value || Boolean(cellData)}
                    onChange={(value) => {
                        if (selectedSchool) {
                            setUtmMasks((prev) => {
                                let newObject = { value };
                                if (value) {
                                    newObject = { ...newObject, ...prev[rowData.id], value };
                                }
                                adsCabinetUpdateUTMMask(parseInt(selectedSchool.id), rowData.id, newObject);
                                return { ...prev, [rowData.id]: newObject };
                            });
                        }
                    }}
                >
                    <Select.Option value={false}>Один параметр в метке</Select.Option>
                    <Select.Option value={true}>Несколько параметров в метке</Select.Option>
                </Select>
                {utmMasks[rowData.id]?.value && (
                    <>
                        <Space
                            style={{ paddingBottom: '21px' }}
                            direction={'vertical'}>
                            <label>Какой по счёту?</label>
                            <InputNumber
                                style={{ width:`${Math.max((utmMasks[rowData.id]?.number?.toString()?.length * 10) + 20,50)}px`}}
                                value={utmMasks[rowData.id]?.number}
                                onChange={(value) => {
                                    if (selectedSchool && value) {
                                        const stringValue = value.toString();
                                        if (stringValue.match(/^\d*$/)) {
                                            const numericValue = parseInt(stringValue, 0);
                                            setUtmMasks((prev) => {
                                                adsCabinetUpdateUTMMask(parseInt(selectedSchool.id), rowData.id, { ...utmMasks[rowData.id], number: numericValue });
                                                return { ...prev, [rowData.id]: { ...prev[rowData.id], number: numericValue } };
                                            });
                                        }
                                    }
                                }}
                            />
                        </Space>
                        <Space
                            style={{ paddingBottom: '21px' }}
                            direction={'vertical'}>
                            <label>Разделитель</label>
                            <Input
                                style={{
                                    width: "30px",
                                    fontSize: "16px",
                                    lineHeight: 0,
                                    fontWeight: 600,
                                }}
                                value={utmMasks[rowData.id]?.divider || ''}
                                onChange={(event) => {
                                    const newValue = event.target.value;
                                    if (/^[^0-9a-zA-Zа-яА-Я]*$/.test(newValue)) {
                                        if (selectedSchool) {
                                            setUtmMasks((prev) => {
                                                adsCabinetUpdateUTMMask(parseInt(selectedSchool.id), rowData.id, { ...utmMasks[rowData.id], divider: newValue });
                                                return { ...prev, [rowData.id]: { ...prev[rowData.id], divider: newValue } };
                                            });
                                        }
                                    }
                                }}
                            />
                        </Space>
                    </>
                )}
            </Space>
        );
    }

    const [columns,setColumns] =
        useState([
            {
                key: 'ad_cabinet',
                title: 'Рекламный кабинет',
                dataKey: 'ad_cabinet',
                width: 300,
                resizable: true,
                cellRenderer: ({ cellData, rowData }:any) => (
                    <Input
                        style={{ minWidth: `${cellData?.length + 1}ch`,height:"unset" }}
                        value={splitNameAndId(cellData)[0]}
                        suffix={<span style={{ fontWeight: '700' }}>{splitNameAndId(cellData)[1]}</span>}
                        onChange={(event) => {
                            if (selectedSchool) {
                                adsCabinetUpdateAdCabinet(parseInt(selectedSchool.id), rowData.id, event.target.value);
                            }
                        }}
                    />
                ),
            },
            {
                key: 'currency_id',
                title: 'Валюта рекламного кабинета',
                dataKey: 'currency_id',
                width: 200,
                resizable: true,
                cellRenderer: ({ cellData, rowData }:any) => (
                    <AutoWidthSelect
                        value={cellData}
                        onChange={(value) => {
                            if (selectedSchool) {
                                adsCabinetUpdateRKCurrency(parseInt(selectedSchool.id), rowData.id, value);
                            }
                        }}
                        options={currencies} />
                ),
            },
            {
                key: 'utm_has_id',
                title: 'В UTM метке есть ID объявления?',
                dataKey: 'utm_has_id',
                width: 200,
                resizable: true,
                cellRenderer: ({ cellData, rowData }:any) => {
                    return (
                        <Select
                            style={{minWidth: '50px'}}
                            value={Boolean(cellData)}
                            onChange={(value) => {
                                if (selectedSchool) {
                                    adsCabinetUpdateUTMStatus(parseInt(selectedSchool.id), rowData.id, value);
                                }
                            }}
                        >
                            <Select.Option value={true}>Да</Select.Option>
                            <Select.Option value={false}>Нет</Select.Option>
                        </Select>
                    )
                },
            },
            {
                key: 'utm_label',
                title: 'В какой UTM метке ID объявления?',
                dataKey: 'utm_label',
                width: 200,
                resizable: true,
                cellRenderer: ({ cellData, rowData }:any) => rowData.utm_has_id && (
                    <Select
                        style={{ minWidth: '120px' }}
                        value={cellData}
                        onChange={(value) => {
                            if (selectedSchool) {
                                adsCabinetUpdateUTMLabel(parseInt(selectedSchool.id), rowData.id, value);
                            }
                        }}
                    >
                        <Select.Option value="utm_source">utm_source</Select.Option>
                        <Select.Option value="utm_medium">utm_medium</Select.Option>
                        <Select.Option value="utm_campaign">utm_campaign</Select.Option>
                        <Select.Option value="utm_term">utm_term</Select.Option>
                        <Select.Option value="utm_content">utm_content</Select.Option>
                        <Select.Option value="utm_group">utm_group</Select.Option>
                    </Select>
                ),
            },
            {
                key: 'utm_mask',
                title: 'Где в UTM метке ID объявления?',
                dataKey: 'utm_mask',
                width: 250,
                resizable: true,
                utmMasks,
                cellRenderer: utmMasksCellRenderer
            },
            {
                title: "Основные UTM метки источника",
                dataIndex: "utm_labels",
                key: "utm_labels",
                cellRenderer: ({ cellData, rowData }: any) => {
                    const hasUTMLabels = rowData.utm_source || rowData.utm_medium || rowData.utm_campaign || rowData.utm_term || rowData.utm_content || rowData.utm_group;

                    if (rowData.utm_has_id && hasUTMLabels) {
                        return (
                            <div
                                style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}
                                onClick={() =>
                                    setUtmLabelsState({
                                        visible: true,
                                        id: rowData.id,
                                        fields: {
                                            utm_source: rowData.utm_source?.split(','),
                                            utm_medium: rowData.utm_medium?.split(','),
                                            utm_campaign: rowData.utm_campaign?.split(','),
                                            utm_term: rowData.utm_term?.split(','),
                                            utm_content: rowData.utm_content?.split(','),
                                            utm_group: rowData.utm_group?.split(','),
                                        },
                                    })
                                }
                            >
                                {rowData.utm_source && <div>utm_source = {rowData.utm_source}</div>}
                                {rowData.utm_medium && <div>utm_medium = {rowData.utm_medium}</div>}
                                {rowData.utm_campaign && <div>utm_campaign = {rowData.utm_campaign}</div>}
                                {rowData.utm_term && <div>utm_term = {rowData.utm_term}</div>}
                                {rowData.utm_content && <div>utm_content = {rowData.utm_content}</div>}
                                {rowData.utm_group && <div>utm_group = {rowData.utm_group}</div>}
                            </div>
                        );
                    } else {
                        return (
                            <Button
                                onClick={() =>
                                    setUtmLabelsState({
                                        visible: true,
                                        id: rowData.id,
                                        fields: {
                                            utm_source: [],
                                            utm_medium: [],
                                            utm_campaign: [],
                                            utm_term: [],
                                            utm_content: [],
                                            utm_group: [],
                                        },
                                    })
                                }
                            >
                                Добавить UTM метки
                            </Button>
                        );
                    }
                },
                width: 250,
            },
            {
                key: 'commission',
                title: 'Конвертация в RUB по курсу ЦБ + %',
                dataKey: 'commission',
                width: 200,
                resizable: true,
                cellRenderer: ({ cellData, rowData }:any) => (
                    <InputNumber
                        value={cellData}
                        min={0}
                        step={1}
                        precision={2}
                        onChange={(value) => {
                            if (selectedSchool && value !== null) {
                                adsCabinetUpdateRKConvert(parseFloat(selectedSchool.id), rowData.id, parseFloat(value.toFixed(2)));
                            }
                        }}
                    />
                ),
            },
            {
                key: 'with_vat',
                title: 'Учитывать НДС 20%',
                dataKey: 'with_vat',
                width: 150,
                resizable: true,
                cellRenderer: ({ cellData, rowData }:any) =>
                    cellData !== null ? (
                        <Switch
                            checked={cellData}
                            onChange={(value) => {
                                if (selectedSchool) {
                                    adsCabinetUpdateRKnds(parseInt(selectedSchool.id), rowData.id, value);
                                }
                            }}
                        />
                    ) : null,
            },
            {
                title: "Статус",
                key: "status",
                dataKey: "status",
                width: 150,
                cellRenderer: ({ cellData, rowData }: any) => (
                    <Tooltip title={cellData}>
                        <Switch
                            checked={cellData == true}
                            onChange={(value) => {
                                if (selectedSchool) {
                                    const update = async () => {
                                        await adsCabinetApi.updateStatus(
                                            parseInt(selectedSchool.id),
                                            rowData.id,
                                            value
                                        );
                                        setRefetch((prev: boolean) => !prev);
                                    };
                                    update();
                                }
                            }}
                        />
                    </Tooltip>
                ),
            },
            {
                key: 'message',
                title: 'Ошибки',
                dataKey: 'message',
                width: 200,
                resizable: true,
            },
            {
                key: 'ads_count_all',
                title: 'Всего объявлений',
                dataKey: 'ads_count_all',
                width: 200,
                resizable: true,
            },
            {
                key: 'ads_count_active',
                title: 'Всего активных объявлений',
                dataKey: 'ads_count_active',
                width: 200,
                resizable: true,
            },
            {
                key: 'ads_count_unprocessed',
                title: 'Объявлений в обработке',
                dataKey: 'ads_count_unprocessed',
                width: 200,
                resizable: true,
            },
            {
                key: 'updated_at',
                title: 'Обновлено',
                dataKey: 'updated_at',
                width: 150,
                resizable: true,
            },
            {
                title: "Действия",
                key: "cab_actions",
                dataKey: "cab_actions",
                width: 140,
                cellRenderer: ({ rowData }: any) => (
                    <Tooltip title="Действия">
                        <Space>
                            <Button
                                type="default"
                                size="small"
                                icon={<RedoOutlined />}
                                onClick={() => handleProcessingRK(rowData)}
                            >
                                Обработать
                            </Button>
                        </Space>
                    </Tooltip>
                ),
            },
        ])

    useEffect(() => {
        const isUtmMasksExist = Object.keys(utmMasks)?.length && Object.values(utmMasks)?.every((el)=>el.value)
        setColumns((prevColumns):any => {
            return prevColumns.map((column) => {
                if (column.key === 'utm_mask') {
                    return {
                        ...column,
                        width:isUtmMasksExist ? 440 : 209,
                        utmMasks,
                        cellRenderer:utmMasksCellRenderer
                    }
                }
                return column
            })
        })
    }, [utmMasks]);


    return (
        <>
            <TableReport
                customColumns={columns}
                apiUrl={API_ADS_GET_DATA}
                metrics={columns.map((el) => ({ name: el.title, dataKey: el.key }))}
                withFilter={true}
                filtersFilter={["Реклама (РР-РК)"]}
                withSegment={true}
            />
            {utmLabelsState.visible && (
                <EditUtm
                    setState={setUtmLabelsState}
                    state={utmLabelsState}
                />
            )}
        </>

    );
};

export default TableCabs;
