import React, { useEffect, useMemo, useState } from "react"

import { ClockCircleOutlined } from "@ant-design/icons"
import { Button, DatePicker, InputNumber, Select, Space, Typography, Radio } from "antd"
import _, { debounce } from "lodash"
import moment from "moment"

import { disabledMinutes, updateFilterItemInList } from "../../../../../../../helpers/utils/reports"
import { useActions } from "../../../../../hooks/useActions"
import { useForceUpdate } from "../../../../../hooks/useForceUpdate"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"
import dayjs from "dayjs";

const DateTimeRangePicker = ({ disabled = false, id, index, modalVisibleHandler, ...props }: any) => {
    const defVal = {
        start: null,
        end: null,
        calendar: { type: "selectdates", time: false, nStart: null, nEnd: null },
        ...props,
    }

    const forceUpdate = useForceUpdate()
    const { filters } = useTypedSelector((state) => state.filter)
    const { setFilters, setFilter, setMetaFilterUpdate } = useActions()

    const [ isEditing, setIsEditing ] = useState(false)
    const [ filterMode, setFilterMode ] = useState(1)
    const [ _id, _setId ] = useState(id)

    useEffect(() => {
        forceUpdate()
    }, [ props.itemData, index, id ])

    useEffect(() => {
        let _tempId = id

        if (filterMode === 2) {
            setFilterMode(2)
            if (!id.includes("not_")) {
                _tempId = `not_${id}`
            }
        } else {
            setFilterMode(1)
            if (id.includes("not_")) {
                _tempId = id.split("not_")[1]
            } else {
                _tempId = id
            }
        }

        _setId(_tempId)
    }, [ id, filterMode ])

    useEffect(() => {
        if (id.includes("not_")) {
            setFilterMode(2)
        } else {
            setFilterMode(1)
        }
    }, [])

    const filter = useMemo(() => {
        let _filter = props.itemData ? props.itemData : null
        if (!props.list) {
            _filter = null
        } else if (!isEditing) {
            setIsEditing(true)
        }

        return _filter ? _filter : defVal
    }, [ filters, _id, index, props.list, props.itemData, props ])

    const [ currentData, setCurrentData ] = useState(filter)

    const format = `DD.MM.YYYY${currentData.calendar.time ? " HH:mm" : ""}`
    const finalFormat = currentData.calendar.time || currentData.calendar.type === "ndaysago" ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"
    const showTime = currentData.calendar.time && { format: "HH:mm", hideDisabledOptions: true }
    const onApply = () => {
            let _currentData = {
            ...currentData,
            id: _id,
            start: currentData.start ? moment(currentData.start).format(finalFormat) :  moment().format(finalFormat),
            end: currentData.end ? moment(currentData.end).format(finalFormat) :  moment().format(finalFormat)
        }

        if (isEditing) {
            let _filters = _.cloneDeep(filters)

            const payload = { ..._currentData }
            _filters = updateFilterItemInList(_filters, index, payload)

            setFilters(_filters)
            setMetaFilterUpdate({value:true})
            modalVisibleHandler(false)

            return
        }

        const payload = {
            ..._currentData,
            id: _id
        }

        if (_id === "uwe_data_webinar" || _id === "uwe_time_webinar" || _id === "data_webinar" || _id === "time_webinar") {
            const duplicateFilter = filters.find((item: any) => item.id === _id)

            if (duplicateFilter) {
                modalVisibleHandler(false)
                return
            }
        } else if (_id === "not_uwe_time_webinar" || _id === "not_uwe_data_webinar" || _id === "not_data_webinar" || _id === "not_time_webinar") {
            const duplicateFilter = filters.find((item: any) => item.id === _id)

            if (duplicateFilter) {
                modalVisibleHandler(false)
                return
            }
        }

        setFilter(payload)
        setMetaFilterUpdate({value:true})
        modalVisibleHandler(false)
    }

    const onChangeDate = (dayJsdate: any, type: string) => {
        const date = moment.isMoment(dayJsdate) ? dayJsdate : moment(dayJsdate.toString());

        if (type === "start") {
            if (!currentData.calendar.time) date && date.set(startTime)
            setCurrentData((prevState: any) => {
                return {
                    ...prevState,
                    start: date
                }
            })
        } else if (type === "end") {
            if (!currentData.calendar.time) date && date.set(endTime)
            setCurrentData((prevState: any) => {
                return {
                    ...prevState,
                    end: date
                }
            })
        }
    }

    const onChangeType = (val: string) => {
        const { start, end } = getStartEndType({ calendar: { type: val } })

        setCurrentData((prevState: any) => {
            return {
                ...prevState,
                calendar: { ...currentData.calendar, nStart: 0, nEnd: 0, type: val },
                start,
                end
            }
        })
    }

    const onClickTime = () => {
        setCurrentData((prevState: any) => {
            return {
                ...prevState,
                calendar: { ...currentData.calendar, time: !currentData.calendar.time }
            }
        })
    }

    const onChangeN = (nType: string, val: any) => {
        let _nStart = currentData.calendar.nStart ? currentData.calendar.nStart : 0
        let _nEnd = currentData.calendar.nEnd ? currentData.calendar.nEnd : 0
        if (nType === "nStart") _nStart = val
        if (nType === "nEnd") _nEnd = val
        let start = moment()
        let end = moment()
        if (currentData.calendar.type === "ndaysago") {
            start = start.subtract(_nStart, "days").set(startTime)
            end = end.subtract(_nEnd, "days").set(endTime)
            if(start.isAfter(end)){
                const values = {
                    start,
                    end
                }
                start = values.end
                end = values.start
            }
        }
        if (currentData.calendar.type === "nhoursago") {
            start = start.subtract(_nEnd + _nStart, "hours")
            end = end.subtract(_nStart, "hours")
        }

        setCurrentData((prevState: any) => {
            let isChangingDirections = false
            if (nType === "nStart" && prevState.calendar.type === "ndaysago" && prevState.calendar.nEnd && val < prevState.calendar.nEnd) {
                // Если nType === "nStart" и nStart больше чем nEnd, меняем их местами
                isChangingDirections = true;
              [val, prevState.calendar.nEnd] = [prevState.calendar.nEnd, val]
            } else if (nType === "nEnd" && prevState.calendar.type === "ndaysago" && prevState.calendar.nStart && val > prevState.calendar.nStart) {
                // Если nType === "nEnd" и nEnd меньше чем nStart, меняем их местами
                isChangingDirections = true;
               [val, prevState.calendar.nStart] = [prevState.calendar.nStart, val]
            }
            return {
                ...prevState,
                start,
                end,
                calendar: { ...currentData.calendar, [nType]: val },
            }
        })
    }
    const debouncedOnChangeN = debounce((nType, val) => onChangeN(nType, val), 300)

    const DP = ({ value, type }: any) => {
        const _val = !value ? null : typeof value === "string" ? dayjs(value) : value
        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <DatePicker
                disabled={disabled}
                value={_val}
                onChange={(date) => onChangeDate(date, type)}
                format={format}
                showTime={showTime}
                disabledMinutes={disabledMinutes}
                style={{ width: 160 }}
            />
        )
    }
    return (
        <Space direction="vertical" style={{ width: 390 }}>
            <div style={{ marginTop: 8 }}>
                <Radio.Group disabled={disabled} onChange={(e) => setFilterMode(e.target.value)} value={filterMode}>
                    <Radio value={1}>Включить</Radio>
                    <Radio value={2}>Исключить</Radio>
                </Radio.Group>
            </div>
            <div>
                <Select disabled={disabled} value={currentData.calendar.type} onChange={onChangeType} style={{ width: "100%", marginBottom: 5 }}>
                    <Select.Option value="selectdates">выбрать даты</Select.Option>
                    <Select.Option value="today">сегодня</Select.Option>
                    <Select.Option value="yesterday">вчера</Select.Option>
                    <Select.Option value="thisweek">эта неделя</Select.Option>
                    <Select.Option value="lastweek">прошлая неделя</Select.Option>
                    <Select.Option value="thismonth">этот месяц</Select.Option>
                    <Select.Option value="lastmonth">прошлый месяц</Select.Option>
                    <Select.Option value="thisquart">этот квартал</Select.Option>
                    <Select.Option value="lastquart">прошлый квартал</Select.Option>
                    <Select.Option value="thisyear">этот год</Select.Option>
                    <Select.Option value="lastyear">прошлый год</Select.Option>
                    <Select.Option value="last7days">последние 7 дней</Select.Option>
                    <Select.Option value="last14days">последние 14 дней</Select.Option>
                    <Select.Option value="last30days">последние 30 дней</Select.Option>
                    <Select.Option value="last365days">последние 365 дней</Select.Option>
                    <Select.Option value="ndaysago">N дней назад</Select.Option>
                    <Select.Option value="nhoursago">N часов назад</Select.Option>
                </Select>
            </div>
            <div>
                {currentData.calendar.type === "selectdates" && (
                    <Space size="small">
                        <Button disabled={disabled} onClick={onClickTime} icon={<ClockCircleOutlined />} type={currentData.calendar.time ? "link" : "text"} />
                        <DP value={currentData.start} type="start" />
                        <Typography.Text type="secondary">—</Typography.Text>
                        <DP value={currentData.end} type="end" />
                    </Space>
                )}
                {[ "ndaysago", "nhoursago" ].includes(currentData.calendar.type) && (
                    <Space size="small">
                        <Typography.Text type="secondary">от</Typography.Text>
                        <InputNumber min={0} disabled={disabled} value={currentData.calendar.nStart} onChange={(val) => debouncedOnChangeN("nStart",val)} style={{ width: 100 }} />
                        {/*<InputNumber onChange={(val) => onChangeN('nStart', val)} style={{ width: 100 }} />*/}
                        <Typography.Text type="secondary">до</Typography.Text>
                        <InputNumber min={0} disabled={disabled} value={currentData.calendar.nEnd} onChange={(val) => debouncedOnChangeN("nEnd",val)} style={{ width: 100 }} />
                        {/*<InputNumber onChange={(val) => onChangeN('nEnd', val)} style={{ width: 100 }} />*/}
                    </Space>
                )}
            </div>
            {![ "selectdates", "allthetime" ].includes(currentData.calendar.type) && (
                <ShowStartEnd start={currentData.start} end={currentData.end} calendar={currentData.calendar} />
            )}
            <Button disabled={disabled} type="primary" style={{ marginTop: 10 }} onClick={onApply}>
                Применить
            </Button>
        </Space>
    )
}
const startTime = { hour: 0, minute: 0, second: 0 }
const endTime = { hour: 23, minute: 59, second: 59 }
const formatView = "DD MMM YYYY HH:mm"

function ShowStartEnd({ start: _start, end: _end, calendar }: any) {
    const { start, end } = getStartEndType({ start: _start, end: _end, calendar })
    const showStartDate = useMemo(() => moment(start).format("dd").toUpperCase(), [ start ])
    const showStartTime = useMemo(() => moment(start).format(formatView), [ start ])
    const showEndDate = useMemo(() => moment(end).format("dd").toUpperCase(), [ end ])
    const showEndTime = useMemo(() => moment(end).format(formatView), [ end ])

    return (
        <Space size="small">
            <div>
                {showStartDate}&nbsp;{showStartTime}
            </div>
            <Typography.Text type="secondary">—</Typography.Text>
            <div>
                {showEndDate}&nbsp;{showEndTime}
            </div>
        </Space>
    )
}

export const getStartEndType = ({ start = null, end = null, calendar }: any) => {
    const { type } = calendar
    if (type === "today") {
        start = moment().set(startTime)
        end = moment().set(endTime)
    } else if (type === "yesterday") {
        start = moment().subtract(1, "days").set(startTime)
        end = moment().subtract(1, "days").set(endTime)
    }else if(type === "last7days"){
        start = moment().subtract(6, "days").set(startTime)
        end = moment().set(endTime)
    }
    else if(type === "last14days"){
        start = moment().subtract(13, "days").set(startTime)
        end = moment().set(endTime)
    }  else if (type === "thisweek") {
        start = moment().isoWeekday(1).set(startTime)
        end = moment().set(endTime)
    } else if (type === "lastweek") {
        start = moment().subtract(1, "week").isoWeekday(1).set(startTime)
        end = moment().subtract(1, "week").isoWeekday(7).set(endTime)
    } else if (type === "thismonth") {
        start = moment().startOf("month").set(startTime)
        end = moment().set(endTime)
    } else if (type === "lastmonth") {
        start = moment().subtract(1, "month").startOf("month").set(startTime)
        end = moment().subtract(1, "month").endOf("month").set(endTime)
    } else if (type === "last30days") {
        start = moment().subtract(29, "days").set(startTime)
        end = moment().set(endTime)
    }else if(type ==="thisquart"){
        start = moment().startOf("quarters").set(startTime)
        end = moment().set(startTime)
    }else if(type === "lastquart"){
        start = moment().subtract(1, "quarters").startOf("quarters").set(startTime)
        end = moment().subtract(1, "quarters").endOf("quarters").set(startTime)
    }else if(type === "thisyear"){
        start = moment().startOf("year").set(startTime)
        end = moment()
    }else if(type === "lastyear"){
        start = moment().subtract(1, "year").startOf("year").set(startTime)
        end = moment().subtract(1, "year").endOf("year").set(startTime)
    }
    else if(type === "last365days"){
        start = moment().subtract(365, "days").set(startTime)
        end = moment().set(endTime)
    } else if (type === "ndaysago") {
        const { nStart, nEnd } = calendar
        start = moment().subtract(nStart, "days").set(startTime)
        end = moment().subtract(nEnd, "days").set(endTime)
    } else if (type === "nhoursago") {
        const { nStart, nEnd } = calendar
        start = moment().subtract(nEnd, "hours")
        end = moment().subtract(nStart, "hours")
    }
    return { start, end }
}

export default DateTimeRangePicker
