import React from "react";
import { createRoot } from "react-dom/client";
import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";
import moment from "moment";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import { initAuthInterceptor } from "./shared/common/api/middleware";
import { store } from "./app/providers/redux/store";
import "moment/locale/ru";

moment.locale("ru");
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
const container = document.getElementById("root");
const root = createRoot(container!)

initAuthInterceptor().finally(() => {
    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <ConfigProvider locale={ruRU}>
                    <App />
                </ConfigProvider>
            </BrowserRouter>
        </Provider>
    );
});
