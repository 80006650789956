import React, { useEffect, useMemo, useState } from "react"

import { Button, Radio, Space, TimePicker, Typography } from "antd"
import _ from "lodash"
import moment from "moment"

import { disabledMinutes, updateFilterItemInList } from "../../../../../../../../../helpers/utils/reports"
import { useActions } from "../../../../../../../hooks/useActions"
import { useForceUpdate } from "../../../../../../../hooks/useForceUpdate"
import { useTypedSelector } from "../../../../../../../hooks/useTypedSelector"

const TimeRangePicker = ({ disabled = false, id, index, modalVisibleHandler, prefix, ...props }: any) => {
    const finalFormat = "HH:mm"

    const forceUpdate = useForceUpdate()

    const filters = useTypedSelector((state) => state.compare.compareFilters[`compareFilters${prefix}`])
    const { setCompareFilter, setMetaFilterUpdate } = useActions()

    const [ isEditing, setIsEditing ] = useState(false)
    const [ filterMode, setFilterMode ] = useState(1)
    const [ _id, _setId ] = useState(id)

    useEffect(() => {
        forceUpdate()
    }, [ props.itemData, index, id ])

    useEffect(() => {
        let _tempId = id

        if (filterMode === 2) {
            setFilterMode(2)
            if (!id.includes("not_")) {
                _tempId = `not_${id}`
            }
        } else {
            setFilterMode(1)
            if (id.includes("not_")) {
                _tempId = id.split("not_")[1]
            } else {
                _tempId = id
            }
        }

        _setId(_tempId)
    }, [ id, filterMode ])

    useEffect(() => {
        if (id.includes("not_")) {
            setFilterMode(2)
        } else {
            setFilterMode(1)
        }
    }, [])

    const filter = useMemo(() => {
        let _filter = props.itemData ? props.itemData : null
        if (!props.list) {
            _filter = null
        } else if (!isEditing) {
            setIsEditing(true)
        }

        return _filter ? _filter : { start: null, end: null, ...props }
    }, [ filters, _id, index, props.list, props.itemData, props ])

    const [ currentData, setCurrentData ] = useState(filter)

    const onApply = () => {
        let _currentData = {
            ...currentData,
            id: _id,
            start: currentData.start ?
                moment(currentData.start, [ moment.ISO_8601, "HH:mm" ]).format(finalFormat) :
                moment(moment("00:00", [ moment.ISO_8601, "HH:mm" ]), [ moment.ISO_8601, "HH:mm" ]).format(finalFormat),
            end: currentData.end ?
                moment(currentData.end, [ moment.ISO_8601, "HH:mm" ]).format(finalFormat) :
                moment(moment("23:59", [ moment.ISO_8601, "HH:mm" ]), [ moment.ISO_8601, "HH:mm" ]).format(finalFormat)
        }

        if (isEditing) {
            let _filters = _.cloneDeep(filters)

            const payload = { ..._currentData }
            _filters = updateFilterItemInList(_filters, index, payload)

            setCompareFilter({ index: prefix, filters: _filters })
            setMetaFilterUpdate({value:true})
            modalVisibleHandler(false)

            return
        }

        const payload = {
            ..._currentData,
            id: _id
        }

        let _filters = _.cloneDeep(filters)
        _filters.push(payload)

        setCompareFilter({ index: prefix, filters: _filters })
        setMetaFilterUpdate({value:true})
        modalVisibleHandler(false)
    }

    const TP = ({ value, type }: any) => {
        const _val = value ? moment(value, [ moment.ISO_8601, "HH:mm" ]) : null

        return (
            <TimePicker
                disabled={disabled}
                placeholder="с"
                format="HH:mm"
                disabledMinutes={disabledMinutes}
                hideDisabledOptions
                showNow={false}
                value={_val as any}
                onChange={(val) => {
                    let _tempVal: moment.Moment | string | null | any = val
                    if (!_tempVal) {
                        if (type === "start") {
                            _tempVal = "00:00"
                        } else {
                            _tempVal = "23:59"
                        }
                    }

                    setCurrentData((prevState: any) => {
                        return {
                            ...prevState,
                            [type]: moment(_tempVal, [ moment.ISO_8601, "HH:mm" ])
                        }
                    })
                }
                }
            />
        )
    }

    return (
    <div>
        <Space size="small" direction="vertical">
            <div style={{ marginTop: 10 }}>
                <Radio.Group disabled={disabled} onChange={(e) => setFilterMode(e.target.value)} value={filterMode}>
                    <Radio value={1}>Включить</Radio>
                    <Radio value={2}>Исключить</Radio>
                </Radio.Group>
            </div>
            <div>
                <TP value={currentData.start} type={"start"} />
                {/*<TP type={'start'} />*/}
                <Typography.Text type="secondary">—</Typography.Text>
                <TP value={currentData.end} type={"end"} />
                {/*<TP type={'end'} />*/}
            </div>
        </Space>
        <br />
        <Button disabled={disabled} type="primary" style={{ marginTop: 20 }} onClick={onApply}>
            Применить
        </Button>
    </div>
    )
}

export default TimeRangePicker
