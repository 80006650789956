import { Table } from "antd";
import React, {useEffect, useState} from "react";
import { Column } from "../../../helpers/utils/functions";
import MainLayout from "../../../shared/common/components/layouts/MainLayout";
import PageHead from "../../../shared/common/components/page-header/PageHeader";
import {unApi} from "../../../shared/common/api/endpoints/reports/unApi";
import {useTypedSelector} from "../../../shared/common/hooks/useTypedSelector";
import {showErrorMessage} from "../../../helpers/utils/ui";
type DataMap = {
    [key: string]: string;
};
const dataMap:DataMap = {
    max_applications_gc_datetime:"Пользователи GC",
    max_applications_scan_datetime:"Регистрации SCAN",
    max_applications_no_datetime:"Регистрации (нулевые заказы) GC",
    max_order_datetime:"Заказы GC",
    max_payment_datetime:"Платежи GC",
    max_hit_datetime:"Посещения",
    max_webinar_gc_datetime:"Вебинары GC",
    max_webinar_bizon_datetime:"Вебинары Bizon365",
    max_advertising_yandex_datetime:"Реклама Яндекс.Директ",
    max_advertising_vk_datetime:"Реклама VK (старый кабинет)",
    max_advertising_fb_datetime:"Реклама Fb.ads",
    max_advertising_google_datetime:"Реклама Google Adwords",
    max_advertising_my_target_datetime:"Реклама MyTarget",
    max_advertising_vk_ads_datetime:"Реклама Vk.ads"
}

const LastData = () => {
    const { selectedSchool } = useTypedSelector((state)=>state.currentSchool)
    const [ tableData,setTableData ] = useState<any[]>([])
    const columns:Column[] = [
        { title: "Название данных", dataIndex: "data_name", key: "data_name" },
        { title: "Дата и время последней загрузки", dataIndex: "data", key: "data" },
    ]
    useEffect(()=>{

        if(selectedSchool?.id){
            const fetchTableData =  async () => {
                try{
                    const response = await unApi.getAllDataTable(+selectedSchool.id)
                    const newData:{[key:string]:any}[] = [];

                    for (const [key, value] of Object.entries(response.data)) {
                        newData.push({
                            data_name: dataMap[key] || key,
                            data: value,
                        });
                    }

                    setTableData(newData);
                    console.log(response.data)
                }catch(err:any){
                    showErrorMessage(err)
                }
            }
            fetchTableData()
        }
    },[selectedSchool])
return (
    <MainLayout pageTitle="Последние полученные данные">
        <div className="page-wrapper">
            <PageHead title="Последние полученные данные" />
            <div className="page-content">
                <div style={{ overflow:"auto",marginTop:"10px" }}>
                    <Table columns={columns} size={"small"} dataSource={tableData} />
                </div>
            </div>
        </div>
    </MainLayout>
)
}
export default LastData
