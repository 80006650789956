import React, { FC, useEffect, useState } from "react"

import {DownloadOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons"
import { Button, Descriptions, Upload } from "antd"

import { uploadsSchoolApi } from "../../../../shared/common/api/endpoints/uploadsSchoolApi"
import MainLayout from "../../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../../shared/common/components/page-header/PageHeader"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import { showErrorMessage } from "../../../../helpers/utils/ui"
import {getUploadProps} from "../../../../helpers/utils/functions";
import usePermission from "../../../../shared/common/hooks/usePermission";
import {useActions} from "../../../../shared/common/hooks/useActions";
import {ImportExcel} from "../../ads/add-spend/components/modals/ImportExcel";

interface IUploadInfo {
    users: string
    orders: string
    payments: string
    app: string
    facebook:string
    ads?: {
        message:string
        file:string
    }
}



const LoadData: FC = () => {
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const checkPermission = usePermission()
    const [ info, setInfo ] = useState<IUploadInfo | null>(null)
    const [importVisible,setImportVisible] = useState<boolean>(false)
    const [ usersLoading, setUsersLoading ] = useState(false)
    const [ ordersLoading, setOrdersLoading ] = useState(false)
    const [ paymentsLoading, setPaymentsLoading ] = useState(false)
    const [ regsLoading, setRegsLoading ] = useState(false)
    const [ manualLoading,setManualLoading ] = useState(false)
    const [facebookLoading,setFacebookLoading] = useState(false)
    const { setAppReportOpen,setDrawerLink } = useActions();
    const makeInfoRequest = () => {
        if (selectedSchool) {
            uploadsSchoolApi.getInfo(selectedSchool.id)
                .then((response) => {
                    if (response.status === 200) {
                        setInfo(response.data)
                    }
                })
                .catch(() => {
                    showErrorMessage("Ошибка при получении данных.")
                })
        }
    }

    useEffect(() => {
        let interval: NodeJS.Timer | undefined = undefined

        if (selectedSchool) {
            makeInfoRequest()
            interval = setInterval(() => makeInfoRequest(), 10000)
        }

        return () => {
            if (interval) {
                clearInterval(interval as NodeJS.Timeout)
            }
        }
    }, [ selectedSchool ])
    const connectStagesBtn = (
        <span
            style={{
                marginLeft: 10,
                marginRight: 10,
                marginTop: 3,
                cursor:"pointer"
            }}
            onClick={() => {
                try{
                    setDrawerLink(`https://help.scananalytics.ru/ruchnaya-zagruzka-dannyh-po-shkole?timestamp=${new Date().getTime()}`)
                    setAppReportOpen(true);
                }
                catch(err){
                    console.log(err)
                }
            }}
        >
                        <QuestionCircleOutlined
                            style={{ color: "#ccc", fontSize: 14,marginTop:"8px",marginLeft:"-10px" }}
                        />
                  </span>
    )

    return (
        <MainLayout pageTitle="Ручная загрузка данных">
            <div className="page-wrapper">
                <PageHead title="Ручная загрузка данных" extraContent={connectStagesBtn} />

                <div className="page-content">
                    <Descriptions
                        bordered
                        column={1}
                        style={{ width: 800 }}
                    >
                        {checkPermission([ "superadmin","admin", "user:contacts", "user:settings" ]) && <Descriptions.Item
                            label="Загрузить пользователей GC"
                            labelStyle={{ minWidth: 300 }}
                        >
                            <div className="description-split">
                                <Upload {...getUploadProps(setUsersLoading,"users",selectedSchool)}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        loading={usersLoading}
                                    >
                                        Загрузить
                                    </Button>
                                </Upload>

                                <div>
                                    { info?.users || "-" }
                                </div>
                            </div>
                        </Descriptions.Item>}
                        {checkPermission([ "superadmin","admin", "user:contacts", "user:settings" ]) && <Descriptions.Item
                            label="Загрузить заказы GC и нулевые заказы GC"
                        >
                            <div className="description-split">
                                <Upload {...getUploadProps(setOrdersLoading,"orders",selectedSchool)}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        loading={ordersLoading}
                                    >
                                        Загрузить
                                    </Button>
                                </Upload>

                                <div>
                                    { info?.orders || "-" }
                                </div>
                            </div>
                        </Descriptions.Item>}
                        {checkPermission([ "superadmin","admin", "user:contacts", "user:settings" ]) && <Descriptions.Item
                            label="Загрузить платежи GC"
                        >
                            <div className="description-split">
                                <Upload {...getUploadProps(setPaymentsLoading,"payments",selectedSchool)}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        loading={paymentsLoading}
                                    >
                                        Загрузить
                                    </Button>
                                </Upload>

                                <div>
                                    { info?.payments || "-" }
                                </div>
                            </div>
                        </Descriptions.Item>}
                        {checkPermission([ "superadmin","admin", "user:contacts", "user:settings" ]) && <Descriptions.Item
                            label="Загрузить регистрации"
                        >
                            <div className="description-split">
                                <Upload {...getUploadProps(setRegsLoading,"app",selectedSchool)}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        loading={regsLoading}
                                    >
                                        Загрузить
                                    </Button>
                                </Upload>

                                <div>
                                    { info?.app || "-" }
                                </div>
                            </div>
                        </Descriptions.Item>}
                        <Descriptions.Item
                            label="Загрузить расходы по Facebook"
                        >
                            <div className="description-split">
                                <Upload {...getUploadProps(setFacebookLoading,"facebook",selectedSchool)}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        loading={facebookLoading}
                                    >
                                        Загрузить
                                    </Button>
                                </Upload>

                                <div>
                                    { info?.facebook || "-" }
                                </div>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Загрузить ручные расходы"
                        >
                            <div className="description-split">
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        loading={importVisible}
                                        onClick={() => setImportVisible(true)}
                                    >
                                        Загрузить
                                    </Button>
                                <div>
                                    { info?.ads?.message || "-" }
                                </div>
                                {info?.ads?.file &&
                                    <Button
                                        type="primary"
                                        icon={<DownloadOutlined />}
                                        loading={importVisible}
                                        onClick={()=>window.open(info?.ads?.file,"_blank")}
                                    >
                                        Cкачать
                                    </Button>
                                }
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
                {importVisible &&
                    <ImportExcel setRefetch={()=>{}} visible={importVisible} setVisible={setImportVisible}/>}
            </div>
        </MainLayout>
    )
}
export default LoadData
