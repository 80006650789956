import React, { FC, useEffect } from "react"
import 'antd/dist/reset.css';
import "./styles/root.scss"
import GlobalErrorWrapper from "../shared/common/components/wrappers/global-error-wrapper/GlobalErrorWrapper"
import { AppRouter } from "./providers/router"
import { bindClickObserver } from "../helpers/utils/hits"
import GlobalModalsWrapper from "../shared/common/components/wrappers/modal-wrapper/GlobalModals";

const App: FC = () => {
    useEffect(() => {
        bindClickObserver()
    }, [])

    return (
    <div id="app" className="App">
      <GlobalErrorWrapper>
          <GlobalModalsWrapper>
              <AppRouter />
          </GlobalModalsWrapper>
      </GlobalErrorWrapper>
    </div>
    )
}

export default App
