import React, {ReactNode} from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
type Props = {
    loading?:boolean;
    hasTip?:boolean;
    children?:ReactNode
}
export default function Loading({ loading, children, hasTip = true }:Props) {
    return (
        <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip={hasTip ? "Загрузка..." : ""}>
            {children}
        </Spin>
    )
}
