import React, { FC, useEffect, useState } from "react"

import { DownOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Input, Popover, Tooltip, Tree, Typography } from "antd"
import _ from "lodash"

import CompareFilterItem from "./CompareFilterItem"
import SelectedCompareFilterItem from "./SelectedCompareFilterItem"
import { generateListKeys, getParentKey } from "../../../../../../../../helpers/utils/reports"
import {data, updateFilters} from "../../../../../../constants/filters"
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector"
import IconFilter from "../../IconFilter"

const listKeys = generateListKeys(data)

const TitleRoot = ({ color, icon, searchName, description }: any) => {
    return (
        <strong style={{ color, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span>
                <IconFilter icon={icon} />
                &nbsp;{searchName}
            </span>

            {
                description ?
                    <span style={{ float: "right", marginRight: 20, display: "inline-flex", alignItems: "center" }}>
                        <Tooltip
                            placement="bottom"
                            title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: description }}></div>}
                            color="#fff"
                            zIndex={9999}
                        >
                            <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 16 }}/>
                        </Tooltip>
                    </span>
                    : null
            }

        </strong>
    )
}

interface ICompareBlockProps {
    prefix: 1 | 2
    filtersFilter?: string[]
}

const CompareFilter: FC<ICompareBlockProps> = ({ prefix, filtersFilter }) => {
    const filters = useTypedSelector((state) => state.compare.compareFilters[`compareFilters${prefix}`])

    const [ visible, setVisible ] = useState(false)
    const [ expandedKeys, setExpandedKeys ] = useState([])
    const [ searchValue, setSearchValue ] = useState("")
    const [ autoExpandParent, setAutoExpandParent ] = useState(true)
    const [ treeData, setTreeData ] = useState([])

    const prepareData = (data: any, searchValue: any, _prefix?: 1 | 2, _color?: any, _icon?: any, _path?: any, _description?: any) => {
        for (let i = 0; i < data.length; i++) {
            const item = data[i]
            const color = _color || item.color
            const icon = _icon || item.icon
            const path = _path ? [ ..._path, item.id ] : [ item.id ]
            const description = _description || item.description

            // поиск
            const reg = new RegExp(searchValue, "i")
            const index = item.name.search(reg)
            const beforeStr = item.name.substr(0, index)
            const searchStr = item.name.substr(index, searchValue.length)
            const afterStr = item.name.substr(index + searchValue.length)
            item.searchName =
                index > -1 ? (
                    <span>
                    {beforeStr}
                        <span className="tree-search-value">{searchStr}</span>
                        {afterStr}
                </span>
                ) : (
                    <span className="tree-search-hide">{item.name}</span>
                )

			const countSelected = filters.filter((f: any) => f.path && f.path.includes(item.id)).length;
            const countEl = countSelected ? <span className="picker-counter-element" /> : null

            if (item.root)
                item.title = () => (
                    <div>
                        <TitleRoot {...item} /> {countEl}
                    </div>
                )
            else if (item.children)
                item.title = () => (
                    <span>
                        {item.searchName} {countEl}
                    </span>
                )
            else item.title = () => {
                return item.description ?
                        <CompareFilterItem
                            prefix={_prefix}
                            index={0}
                            modalVisibleHandler={setVisible}
                            {...item}
                            extra={
                                <span style={{ float: "right", marginRight: 10, display: "inline-flex", alignItems: "center" }}>
                            <Tooltip
                                placement="bottom"
                                title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: item.description }}></div>}
                                color="#fff"
                                zIndex={9999}
                            >
                                <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 16 }}/>
                            </Tooltip>
                        </span>
                            }
                        />
                    :
                        <CompareFilterItem modalVisibleHandler={setVisible} index={0} prefix={_prefix} {...item} />
            }

            item.key = item.id
            item.color = color
            item.icon = icon
            item.path = path
            item.subIcon = description
            if (item.children) {
                prepareData(item.children, searchValue, _prefix, color, icon, path, description)
            }
        }
        return data
    }

    useEffect(() => {
        let data_ = data
        if(location.pathname === '/users/ad-reports'){
            data_ = updateFilters()
        }
        const filteredData = filtersFilter ? data_.filter((item:any) => filtersFilter.includes(item.name)) : data_
        const _treeData = prepareData(filteredData, searchValue, prefix)

        setTreeData(_treeData)
    }, [ data, searchValue, visible, prefix ])

    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    }

    const onChange = (e: any) => {
        const { value } = e.target
        let expandedKeys = []
        if (value !== "") {
            const reg = new RegExp(value, "i")
            expandedKeys = listKeys
                .map((item: any) => {
                    if (item.name.search(reg) > -1) {
                        return getParentKey(item.id, treeData)
                    }
                    return null
                })
                .filter((item: any, i: number, self: any) => item && self.indexOf(item) === i)
        }
        setExpandedKeys(expandedKeys)
        setSearchValue(value)
        setAutoExpandParent(true)
    }

    useEffect(() => {
        setVisible(false)
        setExpandedKeys([])
        setSearchValue("")
    }, [ filters ])

    return (
        <div className="params__filter" id={`filter_${prefix}`}>
            <div style={{ display: "inline" }}>
                <Typography.Text type="secondary">Условия фильтра:&nbsp;</Typography.Text>
                {
                    filters.length === 0 ? null :
                        filters.map((item: any, index: number) => {

                            if (item.type === "datetimerange") {
                                if (!item.start && !item.end) {
                                    return null
                                }
                            }

                            let _prefix = "и"
                            let _secondPrefix: null | string = null
                            const prevIndex = index === 0 ? 0 : index - 1

                            if (filters[prevIndex].id === item.id && !item.id.includes("not_")) {
                                _prefix = "или"
                            }

                            if (item.id.includes("not_")) {
                                _secondPrefix = "не"
                            }

                            return (
                                <>
                                    {index !== 0 &&
                                        <Typography.Text type="secondary">
                                            &nbsp;{_prefix}&nbsp;
                                        </Typography.Text>
                                    }
                                    {
                                        _secondPrefix ?
                                            <b style={{ fontWeight: 600, color: "#ff0000" }}>
                                                {_secondPrefix}&nbsp;
                                            </b>
                                            : ""
                                    }
                                    {
                                        <SelectedCompareFilterItem prefix={prefix} arrayIndex={index} id={item.id} itemData={item} full_name={item.full_name} {...item} />
                                    }
                                </>
                            )
                        })
                }
                &nbsp;
                <Popover
                    content={
                        <Tree.DirectoryTree
                            blockNode
                            switcherIcon={<DownOutlined />}
                            showIcon={false}
                            selectable={false}
                            treeData={treeData}
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                        />
                    }
                    title={<Input onChange={onChange} placeholder="Найти условие" allowClear />}
                    trigger="click"
                    placement="bottom"
                    overlayClassName="filter-popover"
                    visible={visible}
                    onVisibleChange={setVisible}
                >
                    <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        shape="circle"
                    />
                </Popover>
            </div>
        </div>
    )
}

export default CompareFilter
