import React, { FC, useEffect, useMemo, useState } from "react"

import {
    CopyOutlined,
    UploadOutlined,
    ExclamationCircleOutlined,
    EditOutlined,
    DeleteOutlined, RedoOutlined, DownloadOutlined
} from "@ant-design/icons"
import {
    Button, Checkbox,
    Empty,
    Input,
    message,
    Modal,
    notification,
    Pagination,
    Popconfirm,
    Select, Space,
    Tooltip
} from "antd"
import axios from "axios"
import { Properties } from "csstype"
import BaseTable from "react-base-table"
import { useLocation } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import AddOrder from "./components/AddOrder"
import AddPayment from "./components/AddPayment"
import AddTariff from "./components/AddTariff"
import EditOrder from "./components/EditOrder"
import EditPayment from "./components/EditPayment"
import EditSchool from "./components/EditSchool"
import EditUser from "./components/modals/EditUser"
import HeaderCell from "./HeaderCell"
import Metrics from "./metrics/Metrics"
import OverlayTable from "./OverlayTable"
import { IAdsSpendUpdateData } from "../../../../../../app/providers/redux/slices/ads/adsSpend/types"
import {
    checkMoneyDataType,
    formatMoneyData,
    formatSummaryPayments,
    prepareRows
} from "../../../../../../helpers/utils/reports"
import {showInfoNotification, showErrorMessage, showSuccessMessage} from "../../../../../../helpers/utils/ui"
import { adsSpendApi } from "../../../../api/endpoints/adsSpendApi"
import { unApi } from "../../../../api/endpoints/reports/unApi"
import { API_GET_TOKEN } from "../../../../api/urls"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import {SendToProcessing, UpdateOrder} from "../../../../../../app/types/unApi";
import {StatusSelect} from "./components/StatusSelect";
import {crmRequestsApi} from "../../../../api/endpoints/crmRequestsApi";
import usePagination from "../../../../hooks/usePagination";
import usePermission from "../../../../hooks/usePermission";
interface IReportTableProps {
    externalMetrics: any[]
    apiUrl: string
    loadingTable: boolean
    setLoadingTable: (v: boolean) => void
    customColumns?:any
}

let COL_GROUP_MINWIDTH = 250
let COL_WIDTH = 200
let COL_MIN_WIDTH = 200
let COL_MAX_WIDTH = 900
let HEADER_HEIGHT = 70


const ReportTable: FC<IReportTableProps> = ({ externalMetrics, apiUrl, loadingTable, setLoadingTable,customColumns }) => {
    const location = useLocation()
    const [ statusNames, setStatusNames ] = useState({
        data:[],
        loading:true,
    })
    const checkPermission = usePermission()
    const { metricsReport, tableReportData, filtersTable, sortTable, groupings } = useTypedSelector((state) => state.table)
    const { filters } = useTypedSelector((state) => state.filter)
    const { isTableFetching, currency } = useTypedSelector((state) => state.meta)
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { setReportTableData,
        setReportClearTableData,
        setMetaTableFetching,
        setReportMetricsChecked,
        setAdsSpendUpdate,
        adsSpendGetEditData
    } = useActions()
    const [commissionLoading,setCommisionLoading] = useState(false)
    const [ dimensionsTable, setDimensionsTable ] = useState({ height: 0, width: 0 })
    const [ errorTable, setErrorTable ] = useState(false)
    const [ frozensColumns, setFrozensColumns ] = useState<any>({})
    const [ tableWidth, setTableWidth ] = useState(1200)
    const [ columnsTable, setColumnsTable ] = useState<any[]>([])
    const [ showExportModal, setShowExportModal ] = useState(false)
    const [ exportFileName, setExportFileName ] = useState("")
    const [ confirmLoading, setConfirmLoading ] = useState(false)
    const [ totalSum, setTotalSum ] = useState(null)
    const [ editSchoolModalVisible, setEditSchoolModalVisible ] = useState(false)
    const [ currentEditSchoolData, setCurrentEditSchoolData ] = useState({
        id: 0,
    })
    const [ addOrderModalVisible, setAddOrderModalVisible ] = useState(false)
    const [ editOrderModalVisible, setEditOrderModalVisible ] = useState(false)
    const [ editOrderId, setEditOrderId ] = useState(0)
    const [ currentAddPaymentData, setCurrentAddPaymentData ] = useState({
        id: 0,
        schoolName: ""
    })
    const [ isProcessing,setProcessing ] = useState(false)
    const [ addTarifModalVisible, setAddTarifModalVisible ] = useState(false)
    const [ addPaymentModalVisible, setAddPaymentModalVisible ] = useState(false)
    const [ editPaymentModalVisible, setEditPaymentModalVisible ] = useState(false)
    const [ editUserInfo,setEditUserInfo ] = useState({
        visible:false,
        id:0,
    })
    const [ currentEditPaymentData, setCurrentEditPaymentData ] = useState({
        id: 0,
    })
    const { cabinetData } = useTypedSelector(
        (state) => state.adsCabinet
    )
    const [ cabinetPagination,setCabinetPagination,getPaginatedRows ] = usePagination(10,cabinetData?.length)
    useEffect(() => {
        if(customColumns && cabinetData?.length > 0){
            setCabinetPagination((prev)=>({...prev,totalCount:cabinetData?.length}))
        }
    }, [cabinetData]);
    const getInitialPageSize = (pathname=location.pathname) => {
        if(pathname.includes("/advert/rate") || pathname.includes("/source/ads")){
            return 10
        }
        return 30
    }
    const locationState = useLocation()
    const [ tableMetaData, setTableMetaData ] = useState({ start: 0, end: 0, total: 0, currentPage: 1, totalPages: 1, pageSize: getInitialPageSize(locationState.pathname) })
    useEffect(() => {
        const newPageSize = getInitialPageSize(locationState.pathname)
        setTableMetaData(prevState => ({
            ...prevState,
            pageSize: newPageSize
        }))
    }, [locationState.pathname])



    const tableDiv = document.getElementById("universal_table")
    const handleCommissionCharge = async (payment_id:number) => {
        if(commissionLoading){
            return
        }
        try{
            setCommisionLoading(true)
            await unApi.updateTableCommission(payment_id).then((res)=>{
                if(res.status === 200){
                    showSuccessMessage("Успешно пересчитано")
                }
            })
        }catch(err:any){
            if(err.response.data){
                showErrorMessage(err.response.data)
            }else{
                showErrorMessage("Произошла ошибка повторите позже")

            }
        }
        finally {
            setCommisionLoading(false)
        }

    }
    if(location.pathname === "/advert/rate"){
        COL_MIN_WIDTH = 96
        COL_WIDTH = 96
    }else{
        COL_MIN_WIDTH = 200
        COL_WIDTH = 200
    }
    useEffect(() => {
        setReportClearTableData()
    }, [])

    useEffect(() => {
        if (tableDiv) {
            const calcTableWidth = () => {
                const width = tableDiv.offsetWidth
                setTableWidth(width <= 1000 ? 1000 : width)
            }

            new ResizeObserver(calcTableWidth).observe(tableDiv)
            calcTableWidth()
        }
    }, [ tableDiv ])
    const handleSpendDelete = async (data:{date_stat:string,utm_content:number,ads_id:number}) => {
        if(selectedSchool){
            const values = {
                spendId:Number(data.utm_content) || data.ads_id,
                date:data.date_stat,
                schoolId:Number(selectedSchool.id)
            }
            await adsSpendApi.deleteAdsSpend(values.schoolId, values.spendId, values.date)
            setMetaTableFetching({value:true})
        }
    }
    const  replaceHtmlEntities = (text:string) => {
        return text.replace(/&nbsp;/g, ' '); // Заменяет все вхождения '&nbsp;' на обычные пробелы
    }
    const fetchStatuses = async () => {
        try{
            setStatusNames({data:[],loading: true})
            const response = await unApi.getFilterList(0,"list_status_order","")
            if(response.status === 200){
                const data = response.data.map((el:{name:string})=>({...el,name:replaceHtmlEntities(el.name)}))
                setStatusNames({data,loading:false})
            }else{
                throw new Error("Ошибка")
            }
        }catch(err){
            showErrorMessage("Произошла ошибка во время подгрузки статусов.")
        }finally {
            setStatusNames((prevState => ({...prevState,loading: false})))
        }
    }
    const handleSpendEdit = async (data:IAdsSpendUpdateData) => {
        if(selectedSchool?.id){
            adsSpendGetEditData(selectedSchool.id,data.ads_id,data.date_stat)
            setAdsSpendUpdate(true)}
    }

    const loadRows = (page = tableMetaData.currentPage, pageSize = tableMetaData.pageSize) => {
        if (metricsReport.length === 0) return
        setLoadingTable(true)

        const metrics_names = metricsReport.map((col: any) => col.dataKey)
        if(isCustomPage){
            return
        }

        let pageSizeReq:null | number = null
        if(tableMetaData.currentPage === 1 && locationState.pathname.includes("/advert/rate") && locationState.pathname.includes("/source/ads")){
            tableMetaData.total < 30 ? pageSizeReq = 30 : pageSizeReq = null
        }
        const manualSourceFilter = JSON.parse('[{"items":[{"id":8,"name":"Ручной ввод рекламного расхода"}],' +
            '"checkeds":[{"id":8,"name":"Ручной ввод рекламного расхода"}],' +
            '"color":"#a3333d","icon":"DingdingOutlined","name":"Источник рекламы",' +
            '"full_name":"","type":"list","namePath":["","Реклама (РК)","Источник рекламы"],' +
            '"list":false,"id":"advert_list_ads_source"}]')[0]

        const notManualSourceFilter = JSON.parse('[{"items":[{"id":8,"name":"Ручной ввод рекламного расхода"}],' +
            '"checkeds":[{"id":8,"name":"Ручной ввод рекламного расхода"}],"color":"#a3333d","icon":"DingdingOutlined",' +
            '"name":"Источник рекламы","full_name":"","type":"list","namePath":["","Реклама (РК)","Источник рекламы"],' +
            '"list":false,"id":"not_advert_list_ads_source"}]')[0]

        const filtersData = structuredClone(filters)

        let advertCabsFields:null | { type: any , school_id:any,status_id:any,filters:any} = null

        if((location.pathname.includes("advert/rate") || location.pathname.includes('/source/ads')) && selectedSchool && isFirstTab()){
            const status_id = Number(new URLSearchParams(location.search).get('sub')) || 1
            const type = location.pathname.includes("advert/rate") ? 2 : 1
            console.log(type)
            advertCabsFields = {
                type,
                school_id: Number(selectedSchool.id),
                status_id,
                filters: filtersData,
            }
        }

        if(location.pathname.includes("advert/rate")){
            filtersData.push(manualSourceFilter)
        }
        if(location.pathname.includes('/source/ads')){
            filtersData.push(notManualSourceFilter)
        }
        const data = advertCabsFields || {
            filters: filtersData,
            page,
            pageSize:pageSizeReq || pageSize,
            metrics_names,
            sorting: sortTable,
        }

        console.log("=>> REQUEST =>>\n", data, "\n=>> REQUEST =>>")
        unApi.getTableReportData(apiUrl, data, location.pathname)
            .then((response) => {
                const data = response.data
                console.log("<<= RESPONSE <<=\n", data, "\n<<= RESPONSE <<=")
                if (response.status === 200 && data?.rows) {

                    setReportTableData({ ...data, groupings: groupings })

                    setTotalSum(data.totalPaymentsSum || null)
                    setTableMetaData({
                        total: data.total,
                        totalPages: data.totalPages,
                        start: data.start,
                        end: data.end,
                        currentPage: data.currentPage,
                        pageSize: data.pageSize
                    })
                } else {
                    setErrorTable(true)
                    console.log("Ошибка")
                }
                setLoadingTable(false)
            })
            .catch((error) => {
                console.log("error = ", error)
                setErrorTable(true)
                setLoadingTable(false)
            })
    }

    useEffect(() => {
        setTimeout(() => setMetaTableFetching({value:true}), 100)
        if(location.pathname.includes('/users/orders')){
            fetchStatuses()
        }
    }, [])

    useEffect(() => {
        if (!isTableFetching) return

        setErrorTable(false)
        loadRows()

        setMetaTableFetching({value:false})
    }, [ isTableFetching ])

    useEffect(() => {
        if (metricsReport.length === 0) return
        setReportMetricsChecked([ { name: metricsReport[0].dataKey, label: metricsReport[0].name } ])
    }, [ metricsReport ])
    useEffect(() => {
        const docEl = document.documentElement
        setDimensionsTable({
            height: docEl.clientHeight - 200,
            width: tableWidth,
        })
    }, [ tableWidth ])

    useEffect(() => {
        if (metricsReport.length === 0) {
            setTableMetaData({ start: 0, end: 0, total: 0, currentPage: 1, totalPages: 1, pageSize: getInitialPageSize(locationState.pathname) })
        }
    }, [ metricsReport.length ])

    const widthGroupCol = useMemo(() => {
        let width = dimensionsTable.width - 20 - metricsReport.length * COL_WIDTH
        return width > COL_GROUP_MINWIDTH ? width : COL_GROUP_MINWIDTH
    }, [ dimensionsTable.width, metricsReport.length ])
    const startDetails = (id:number) => {
        Modal.confirm({
            title: "Вы уверены?",
            icon: <ExclamationCircleOutlined />,
            okText: "Да",
            cancelText: "Нет",
            onOk: () => {
                axios.post("/detailing/add", { id: id })
                    .then((response) => {
                        if (response.status === 200 && response.data.status === true) {
                            showInfoNotification(response.data.msg)
                        } else {
                            showErrorMessage(response.data.msg)
                        }
                    })
                    .catch(() => {
                        showErrorMessage("Произошла ошибка, повторите попытку позже.")
                    })
            },
        })
    }
    const handleStatusSelect = async (data:UpdateOrder) => {
        try{
            const response = await unApi.updateOrderStatus(data)
            if(response.status === 200){
                showSuccessMessage("Статус обновлен.")
            }
        }catch(err) {
            showErrorMessage("Произошла ошибка во время обновления статуса.")
        }
    }
    const startImpersonation = (id:number) => {
        Modal.confirm({
            title: "Вы уверены?",
            icon: <ExclamationCircleOutlined />,
            okText: "Да",
            cancelText: "Нет",
            onOk: () => {
                axios.get(`${API_GET_TOKEN}/${id}`)
                    .then((response) => {
                        if (response.status === 200) {
                            localStorage.setItem("access_admin",localStorage.getItem("access")!)
                            localStorage.setItem("access",response.data.access_token)
                            showInfoNotification("Успешно зашли под пользователем")
                            window.location.href = "/"
                        } else {
                            showErrorMessage("Не удалось войти под пользователем")
                        }
                    })
                    .catch(() => {
                        showErrorMessage("Произошла ошибка, повторите попытку позже.")
                    })
            },
        })
    }
    const handleCopyToClipboard = (text:string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                message.success({
                    content: "Скопировано",
                    style: {
                        marginTop: "20%",

                    },
                })
            })
            .catch(() => {
                message.error({
                    content: "Ошибка при копировании данных",
                    style: {
                        marginTop: "20%",
                    },
                })
            })
    }
    useEffect(() => {
        let cols:any = [
            ...metricsReport.map((item: any) => {
                const { name, description, dataKey } = item
                return {
                    title: name,
                    description,
                    dataKey,
                    key: dataKey,
                    width: COL_WIDTH,
                    minWidth: COL_MIN_WIDTH,
                    maxWidth: COL_MAX_WIDTH,
                    resizable: true,
                    filtersTable,
                    sortTable,
                    statusNames,
                    frozen: frozensColumns[dataKey],
                    headerRenderer: ({ column }: any) => (
                        <HeaderCell column={column} filters={filtersTable} sort={sortTable} setFrozens={setFrozensColumns} />
                    ),
                    cellRenderer: (renderItem: any) => {
                        const customStyle: Properties = {
                            width: "100%",
                            backgroundImage: "unset",
                            textAlign: "right",
                            display:"flex",
                            justifyContent:"flex-end",
                            alignItems:"center",
                            height:`${rowHeight}px`,
                            paddingRight:"4px"

                        }

                        if (checkMoneyDataType(dataKey)) {
                            return (
                                <Tooltip
                                    placement="top"
                                    title={`${renderItem.cellData}`}
                                    style={{ textOverflow: "ellipsis" }}
                                >
                                    {formatMoneyData(renderItem.cellData, currency || "")}
                                </Tooltip>
                            )
                        }
                        if (renderItem.column.title === "id школы" && location.pathname.includes("crm/schools")) {
                            return (
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setCurrentEditSchoolData({ id: renderItem.rowData.id })
                                        setEditSchoolModalVisible(true)
                                    }}
                                >
                                    {renderItem.cellData}
                                </Button>
                            )
                        }
                        if (renderItem.column.title === "id пользователя" && location.pathname.includes("crm/users")) {
                            return (
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setEditUserInfo({ id: Number(renderItem.rowData.id),visible: true })
                                    }}
                                >
                                    {renderItem.cellData}
                                </Button>
                            )
                        }
                        if (renderItem.column.dataKey === "query_error" && location.pathname.includes("crm/requests")) {
                            return !renderItem.cellData ? `Нет текста ошибки` : (
                                <Button
                                    type="default"
                                    disabled={!renderItem.cellData}
                                    onClick={() => {
                                        handleCopyToClipboard(renderItem.cellData)
                                    }}
                                >
                                    {renderItem.cellData ? `Скопировать` : `Нет текста ошибки`}
                                </Button>
                            )
                        }
                        if (renderItem.column.dataKey === "base_query_file_link" && location.pathname.includes("crm/requests")) {
                            return !renderItem.cellData ? `Нет текста ошибки` : (
                                <Space>
                                    <Button
                                        type="default"
                                        disabled={!renderItem.cellData}
                                        icon={<DownloadOutlined />}
                                        onClick={() =>crmRequestsApi.downloadSqlRequest(renderItem.cellData)}
                                    >
                                    </Button>
                                    {renderItem.cellData ? renderItem.cellData : `Нет базового запроса.`}
                                </Space>

                            )
                        }
                        if (renderItem.column.dataKey === "total_query_file_link" && location.pathname.includes("crm/requests")) {
                            return !renderItem.cellData ? `Нет текста ошибки` : (
                                <Space>
                                    <Button
                                        type="default"
                                        disabled={!renderItem.cellData}
                                        icon={<DownloadOutlined />}
                                        onClick={() =>crmRequestsApi.downloadSqlRequest(renderItem.cellData)}
                                    >
                                    </Button>
                                    {renderItem.cellData ? renderItem.cellData : `Нет тотал запроса.`}
                                </Space>

                            )
                        }
                        if (renderItem.column.dataKey === "filters" && location.pathname.includes("crm/requests")) {
                            return !renderItem.cellData ? `Нет фильтров` : (
                                <Button
                                    type="default"
                                    disabled={!renderItem.cellData}
                                    onClick={() => {
                                        handleCopyToClipboard(renderItem.cellData)
                                    }}
                                >
                                    {renderItem.cellData ? `Скопировать` : `Нет фильтров`}
                                </Button>
                            )
                        }
                        if (renderItem.column.title === "Ссылка на чат" && location.pathname.includes("crm/schools")) {
                            return (
                                <a href={renderItem.cellData} target="_blank">
                                    {renderItem.cellData}
                                </a>
                            )
                        }
                        if (renderItem.column.title === "Чат" && !location.pathname.includes("crm/orders")) {
                            return (
                                <span dangerouslySetInnerHTML={{ __html: renderItem.cellData }} />
                            )
                        }
                        if (renderItem.column.title === "Чат" && location.pathname.includes("crm/orders")) {
                            return (
                                <a href={renderItem.cellData} target="_blank">
                                    {renderItem.cellData}
                                </a>
                            )
                        }
                        if (renderItem.column.title === "URL адрес") {
                            return (
                                <a href={renderItem.cellData} target="_blank">
                                    {renderItem.cellData}
                                </a>
                            )
                        }

                        if (renderItem.column.title === "Номер заказа") {
                            if (location.pathname.includes("crm/orders")) {
                                return (
                                    <div>
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                setEditOrderId(renderItem.rowData.order_id)
                                                setEditOrderModalVisible(true)
                                            }}
                                        >
                                            {renderItem.cellData}
                                        </Button>
                                        <Button
                                            type="default"
                                            size="small"
                                            onClick={() => {
                                                setCurrentAddPaymentData({
                                                    id: renderItem.rowData.order_id,
                                                    schoolName: renderItem.rowData.school_name
                                                })
                                                setAddPaymentModalVisible(true)
                                            }}

                                        >
                                            Добавить платеж
                                        </Button>
                                    </div>
                                )
                            }else{
                                return (
                                    <Tooltip
                                        placement="top"
                                        title={`${renderItem.cellData}`}
                                        style={{ textOverflow: "ellipsis" }}
                                    >
                                        {renderItem.cellData}
                                    </Tooltip>
                                )
                            }

                        }

                        if(renderItem.column.title === "Статус" && location.pathname.includes('/users/orders')){
                            return (
                                <StatusSelect statusNames={statusNames} selectedSchool={selectedSchool} handleStatusSelect={handleStatusSelect} renderItem={renderItem} />
                            )
                        }
                        if (renderItem.column.title === "id платежа") {
                            return (
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setCurrentEditPaymentData({ id: renderItem.rowData.payment_id })
                                        setEditPaymentModalVisible(true)
                                    }}
                                >
                                    {renderItem.cellData}
                                </Button>
                            )
                        }
                        if (renderItem.column.title === "Ссылка на оплату заказа") {
                            return (
                                <Button
                                    type="primary"
                                >
                                    <a href={`${renderItem.cellData}`} target="_blank">
                                        Оплатить
                                    </a>
                                </Button>
                            )
                        }

                        if(renderItem.column.dataKey === "processing_status"){
                            const isRegsPage = locationState.pathname.includes('/users/registration')
                            const rowData = renderItem?.rowData
                            const hasUTMLabels = rowData.utm_source_value || rowData.utm_medium_value || rowData.utm_campaign_value || rowData.utm_term_value || rowData.utm_content_value || rowData.utm_group_value;
                            const getTitle = () => {
                                if(renderItem.cellData) {
                                    return "Объявление обработано для связи с регистрациями."
                                }
                                if(renderItem.cellData === null || (isRegsPage && hasUTMLabels === "-")){
                                    return "У регистрации нет UTM меток. SCAN не может привязать эту регистрацию ни к одному рекламному объявлению."
                                }
                                if(renderItem.cellData === null){
                                    return "Объявление не подходит для связывания с регистрациями. Если вы хотите увидеть сколько было регистраций с этого объявления, вам нужно добавить UTM метки в рекламную ссылку. Тогда SCAN по UTM меткам найдёт регистрации у которых точно такие же метки как у этого объявления и свяжет их."
                                }
                                return "Объявление ещё не обработано для связи с регистрациями"
                            }
                            return (
                                <Tooltip
                                    placement="top"
                                    title={getTitle()}
                                    style={{ textOverflow: "ellipsis" }}
                                >
                                    <Checkbox disabled={renderItem.cellData === null || (isRegsPage && hasUTMLabels === "-")} checked={renderItem.cellData} />
                                </Tooltip>
                            )
                        }

                        return `${renderItem.cellData}`.includes("<a") ? (
                            <span dangerouslySetInnerHTML={{ __html: renderItem.cellData }} />
                        ) : (
                            <Tooltip
                                placement="top"
                                title={`${renderItem.cellData}`}
                                style={{ textOverflow: "ellipsis" }}
                            >
                                {renderItem.cellData}
                            </Tooltip>
                        )
                    }
                }
            }),
        ]
        if (location.pathname.includes("crm/schools")) {
            cols.push({
                frozen: undefined,
                headerRenderer({ column }: any): JSX.Element {
                    return <h1>Действия</h1>
                },
                filtersTable,
                sortTable,
                title: "Действия",
                description: "",
                dataKey: "school_static_actions",
                key: "school_static_actions",
                width: COL_WIDTH,
                minWidth: COL_MIN_WIDTH,
                maxWidth: COL_MAX_WIDTH,
                resizable: true,
                cellRenderer: (renderItem:any) => {
                    return (
                        <div>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => startDetails(renderItem.rowData.id)}
                            >
                                Запустить детализацию
                            </Button>
                        </div>
                    )
                }
            })
        }
        if (location.pathname.includes("crm/payments")) {
            cols.push({
                frozen: undefined,
                headerRenderer({ column }: any): JSX.Element {
                    return <h1>Действия</h1>
                },
                filtersTable,
                sortTable,
                title: "Действия",
                description: "",
                dataKey: "school_static_actions",
                key: "school_static_actions",
                width: COL_WIDTH,
                minWidth: COL_MIN_WIDTH,
                maxWidth: COL_MAX_WIDTH,
                resizable: true,
                cellRenderer: (renderItem:any) => {
                    return (
                        <div style={{ display:"flex",columnGap:"4px",justifyContent:"center",alignItems:"center" }}>
                            <Button title={"Пересчитать комиссию"} icon={<RedoOutlined />} onClick={()=>handleCommissionCharge(renderItem.rowData.payment_id)} />
                        </div>
                    )
                }
            })
        }
        if (location.pathname.includes("crm/users")) {
            cols.push({
                frozen: undefined,
                headerRenderer(): JSX.Element {
                    return <h1>Действия</h1>
                },
                filtersTable,
                sortTable,
                title: "Действия",
                description: "",
                dataKey: "school_static_actions",
                key: "school_static_actions",
                width: COL_WIDTH,
                minWidth: COL_MIN_WIDTH,
                maxWidth: COL_MAX_WIDTH,
                resizable: true,
                cellRenderer: (renderItem:any) => {
                    return (
                        <div>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => startImpersonation(renderItem.rowData.id)}
                            >
                                Зайти под пользователем
                            </Button>
                        </div>
                    )
                }
            })
        }
        if (location.pathname.includes("advert/rate")) {
            cols.push({
                frozen: undefined,
                headerRenderer({ column }: any): JSX.Element {
                    return (
                        <div className="table-header-cell" style={{ justifyContent:"flex-start",alignItems:"flex-start" }}>
                            <div style={{ cursor:"pointer" }}>
                                <div className="table-header-sort table-header-withIcon table-header-sort-right">Действия</div>
                            </div>
                        </div>
                    )
                },
                filtersTable,
                sortTable,
                title: "Действия",
                description: "",
                dataKey: "rate_static_actions",
                key: "rate_static_actions",
                width: 74,
                minWidth: 74,
                maxWidth: COL_MAX_WIDTH,
                resizable: true,
                cellRenderer: (renderItem:any) => {
                    const customStyle: Properties = {
                        width: "100%",
                        backgroundImage: "unset",
                        textAlign: "right",
                        display:"flex",
                        justifyContent:"flex-end",
                        alignItems:"center",
                        height:`${rowHeight}px`,
                        columnGap:"8px"

                    }

                    return (
                        <div style={customStyle}>
                            <Button
                                type="default"
                                icon={<EditOutlined />}
                                size="small"
                                onClick={() => handleSpendEdit(renderItem.rowData)}
                            />
                            <Popconfirm
                                title="Подтвердите удаление"
                                onConfirm={() => handleSpendDelete(renderItem.rowData)}
                                okText="Удалить"
                                cancelText="Отмена"
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    type="default"
                                    size="small"
                                    danger
                                />
                            </Popconfirm>
                        </div>
                    )
                }
            })
        }
        setColumnsTable(cols)
    }, [ metricsReport, widthGroupCol, filtersTable, sortTable, currency, frozensColumns,statusNames ])

    const isFirstTab = () => {
        return (Number(new URLSearchParams(locationState.search).get('tab')) || 1) === 1
    }
    const isSecondTab = () => {
        return (Number(new URLSearchParams(locationState.search).get('tab')) || 2) === 2
    }
    const isAdsPage = (locationState.pathname.includes("/source/ads") || locationState.pathname.includes("/advert/rate")) && isFirstTab()
    const isAdAdsPage = locationState.pathname.includes("/source/ads") || locationState.pathname.includes("/advert/rate") || isSecondTab()
    const copyVisibleTableData = () => {
        const parseData = () => {

            const parseRow = (item: any) => {
                let _row: any[] = []

                Object.entries(item).forEach(([ key, value ]) => {
                    if (key !== "pathStr" && key !== "children" && key !== "clientData") {
                        metricsReport.forEach((metricItem: any, index: number) => {
                            if (key === metricItem.dataKey) {
                                let _val = value === 0 ? "0" : value
                                _row[index + 1] = `${_val ? `${_val}`.replaceAll(".", ",") : " "}`
                            }
                        })
                    }
                })

                return [ _row ]
            }

            let copyDataStr = ""

            tableReportData.data.forEach((item: any) => {
                let [ _row ] = parseRow(item)

                let strRow = ""
                _row.forEach((item) => {
                    strRow += `${item}\t`
                })
                strRow += "\n"

                copyDataStr += strRow
            })

            return copyDataStr
        }

        try {
            let copyDataStr = ""
            let headerRowStr = ""

            metricsReport.forEach((metricItem: any) => {
                headerRowStr += `${metricItem.name}\t`
            })

            copyDataStr += `${headerRowStr}\n`

            copyDataStr += parseData()

            navigator.clipboard.writeText(copyDataStr)
                .then(() => {
                    message.success({
                        content: "Скопировано",
                        style: {
                            marginTop: "20%",

                        },
                    })
                })
                .catch(() => {
                    message.error({
                        content: "Ошибка при копировании данных",
                        style: {
                            marginTop: "20%",
                        },
                    })
                })
        } catch (err) {
            message.error({
                content: "Ошибка при копировании данных",
                style: {
                    marginTop: "20%",
                },
            })
        }
    }
    const isCustomPage = customColumns?.length > 0
    const copyAllTableData = () => {
        setConfirmLoading(true)

        const data = {
            school_id: selectedSchool?.id || "",
            name: exportFileName,
            values: JSON.stringify(filters)
        }

        unApi.getExportTable(data, location.pathname)
            .then((response) => {
                if (response.status === 200) {
                    setConfirmLoading(false)
                    setShowExportModal(false)

                    window.open("/users/reports", "_blank")
                } else {
                    notification.error({
                        message: "Ошибка!",
                        description: "Произошла ошибка, повторите попытку позже.",
                        duration: 7,
                        placement: "topRight"
                    })
                    setConfirmLoading(false)
                }
            })
            .catch((err: any) => {
                console.error(err)
                notification.error({
                    message: "Ошибка!",
                    description: "Произошла ошибка, повторите попытку позже.",
                    duration: 7,
                    placement: "topRight"
                })
                setConfirmLoading(false)
            })
    }
    const tableData = useMemo(() => {
        if(isCustomPage){
            return prepareRows({ rows: getPaginatedRows(cabinetData,cabinetPagination.page,cabinetPagination.pageSize), groupings })
        }else {
            return tableReportData.data
        }
    },[cabinetData,isCustomPage,cabinetPagination,tableReportData])
    const getHeight = (): number => {
        const utms = [
            "utm_source",
            "utm_medium",
            "utm_campaign",
            "utm_term",
            "utm_group",
            "utm_content"
        ]
        const maxUtmsAmount = tableData.reduce((maxCount:number, el: Record<string, any>) => {
            const validUtms = Object.keys(el).filter((key) => el[key] !== null && utms.includes(key));
            return Math.max(maxCount, validUtms.length);
        }, 0);

        const height = maxUtmsAmount * 17;
        return Math.max(height, 84);
    };

    const rowHeight = useMemo(()=>isAdsPage ? getHeight() : 37,[locationState.pathname,tableData])


    const filteredColumns = useMemo(() => {
        if(!customColumns) return []
        return metricsReport
            .map((metric) => customColumns.find((column:{title:string}) => column.title === metric.name))
            .filter((column) => column !== undefined);
    }, [customColumns, metricsReport]);

    const isAdTab = Number(new URLSearchParams(location.search).get('tab')) === 1
    const isRegs = location.pathname === "/users/registration"

    const handleRegsProcessing = async () => {
        try{
            setProcessing(true)
            const values = {
                school_id: (selectedSchool?.id as string),
                ...(filters?.length > 0 && {filters})
            } as SendToProcessing
            await unApi.sendToProcessing(values).then((res)=>{
                if(res.status === 200){
                    showSuccessMessage("Регистрации поставлены в очередь на привязку к объявлениям.")
                    setMetaTableFetching({value:true})
                }
            })
        }catch(err:any){
            if(err.response.data){
                showErrorMessage(err.response.data)
            }else{
                showErrorMessage("Произошла ошибка повторите позже")
            }
        }
        finally {
            setProcessing(false)
        }
    }
    const handleRemoveAds = async () => {
        try{
            setProcessing(true)
            if(filters.length === 0){
                showErrorMessage("Выберите фильтры для удаления объявлений")
                setProcessing(false)
            }
            const values = {
                school_id: (selectedSchool?.id as string),
                ...(filters?.length > 0 && {filters})
            } as SendToProcessing
            await unApi.massRemoving(values).then((res)=>{
                if(res.status === 200){
                    showSuccessMessage("Объявления успешно удалены.")
                    setMetaTableFetching({value:true})
                }
            })
        }catch(err:any){
            if(err.response.data){
                showErrorMessage(err.response.data)
            }else{
                showErrorMessage("Произошла ошибка повторите позже")
            }
        }
        finally {
            setProcessing(false)
        }
    }

    return (
        <div className="universal-table" id="universal_table">
            <div className="universal-table-settings" style={{ width: dimensionsTable.width, ...(isCustomPage && { display:"flex",justifyContent:"flex-end" }) }}>
                {!isCustomPage &&
                    <div style={{fontSize: 14, fontWeight: 500}}>
                        <span>Показано {tableMetaData.start} - {tableMetaData.end} строк из {tableMetaData.total}</span>
                        {
                            totalSum ?
                                <span style={{marginLeft: 10, paddingLeft: 10, borderLeft: "1px solid #d4d4d4"}}>
                                Общая сумма платежей: {formatSummaryPayments(totalSum, window.localStorage.getItem("current_currency") || currency)}
                            </span>
                                : null
                        }
                    </div>
                }

                <div>
                    {
                        isRegs && (
                            <Tooltip
                                title={<div className="page-title-tooltip-text">Перепривязать регистрации к рекламным объявлениям</div>}
                                color="#fff"
                                zIndex={9999}
                                placement="topLeft"
                            >
                                <Button
                                    icon={<RedoOutlined />}
                                    type="text"
                                    style={{marginLeft: 5}}
                                    disabled={isProcessing || loadingTable || tableReportData.data.length === 0 || metricsReport.length === 0}
                                    onClick={handleRegsProcessing}
                                />
                            </Tooltip>
                        )
                    }
                    {
                        isAdAdsPage && checkPermission(["superadmin"]) && (
                            <Tooltip
                                title={<div className="page-title-tooltip-text">Массовое удаление объявлений</div>}
                                color="#fff"
                                zIndex={9999}
                                placement="topLeft"
                            >
                                <Popconfirm
                                    title="Вы уверенны, что хотите массово удалить объявления?"
                                    onConfirm={handleRemoveAds}
                                    okText="Да"
                                    cancelText="Нет"
                                    disabled={isProcessing || loadingTable || tableReportData.data.length === 0 || metricsReport.length === 0}
                                >
                                <Button
                                    icon={<DeleteOutlined />}
                                    type="text"
                                    style={{marginLeft: 5}}
                                    disabled={isProcessing || loadingTable || tableReportData.data.length === 0 || metricsReport.length === 0}
                                />
                                </Popconfirm>
                            </Tooltip>
                        )
                    }
                    {!isCustomPage && <>
                        <Tooltip
                            title={<div className="page-title-tooltip-text">Экспорт таблицы в формате .csv</div>}
                            color="#fff"
                            zIndex={9999}
                            placement="topLeft"
                        >
                            <Button
                                icon={<UploadOutlined/>}
                                type="text"
                                style={{marginLeft: 5}}
                                disabled={loadingTable || tableReportData.data.length === 0 || metricsReport.length === 0}
                                onClick={() => setShowExportModal(true)}
                            />
                        </Tooltip>
                        <Tooltip
                            title={<div className="page-title-tooltip-text">Копирование видимых на данный момент данных таблицы в буфер обмена, чтобы можно было вставить данные в Excel или Гугл-таблицы.</div>}
                            color="#fff"
                            zIndex={9999}
                            placement="topLeft"
                        >
                            <Button
                                icon={<CopyOutlined />}
                                type="text"
                                style={{ marginLeft: 5 }}
                                disabled={loadingTable || tableReportData.data.length === 0 || metricsReport.length === 0}
                                onClick={() => copyVisibleTableData()}
                            />
                        </Tooltip>
                        {
                           isAdTab && <Popconfirm
                                title="Подтвердите удаление"
                                onConfirm={() => showErrorMessage("Подключить запрос")}
                                okText="Удалить"
                                cancelText="Отмена"
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    type="text"
                                    style={{ marginLeft: 5 }}
                                    disabled={loadingTable || tableReportData.data.length === 0 || metricsReport.length === 0}
                                />
                            </Popconfirm>
                        }


                    </>
                    }
                    {
                        location.pathname === "/crm/tariff" ?
                            <Button
                                type="primary"
                                onClick={() => setAddTarifModalVisible(true)}
                                style={{
                                    marginLeft: 5
                                }}
                            >
                                Добавить тариф
                            </Button>
                            : null
                    }
                    {
                        location.pathname === "/crm/orders" ?
                            <Button
                                type="primary"
                                onClick={() => setAddOrderModalVisible(true)}
                                style={{
                                    marginLeft: 5
                                }}
                            >
                                Добавить заказ
                            </Button>
                            : null
                    }
                    <Metrics
                        metricsData={externalMetrics}
                        isDisable={false}
                    />
                </div>
            </div>
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/* @ts-ignore*/}
            <BaseTable
                fixed
                columns={isCustomPage ? filteredColumns : columnsTable}
                data={tableData}
                rowKey={uuidv4()}
                width={dimensionsTable.width}
                maxHeight={600}
                headerHeight={Object.keys(filtersTable).length > 0 ? HEADER_HEIGHT + 15 : HEADER_HEIGHT}
                disabled={loadingTable}
                overlayRenderer={
                    <OverlayTable
                        headerHeight={metricsReport.length === 0 ? 10 : HEADER_HEIGHT}
                        empty={(!isCustomPage && (tableReportData.data.length === 0 || metricsReport.length === 0)) || (isCustomPage && cabinetData?.length === 0)}
                        error={errorTable}
                    />
                }
                rowHeight={rowHeight}
                rowRenderer={
                    ({ rowData, cells }: any) => {
                        let customStyle = {
                            height: `${rowHeight}px`,
                        }
                        if(location.pathname === "/advert/rate"){
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            customStyle = { ...customStyle,textAlign:"center" }
                        }
                        if ((rowData.group === "empty" && !isCustomPage) || (isCustomPage && cabinetData?.length === 0))
                            return (
                                <div className="table-row-empty" style={customStyle}>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={false}
                                        imageStyle={{ height: 27, marginBottom: 0 }}
                                    />
                                    Нет данных, соответствующих условиям отчёта.
                                </div>
                            )
                        return <div className="BaseTable__row BaseTable__row--customized" style={customStyle}>
                            {cells}
                        </div>
                    }
                }
            />
            <div style={{ marginTop: 20, marginBottom: 0 }}>
                {
                    tableMetaData.total > 0 &&
                        <Pagination
                            current={tableMetaData.currentPage}
                            total={tableMetaData.total}
                            pageSize={tableMetaData.pageSize}
                            showSizeChanger={true}
                            pageSizeOptions={[ 10, 30, 60, 100 ]}
                            onChange={(page, pageSize) => {
                                // const totalPages = Math.ceil(tableMetaData.total / tableMetaData.pageSize);
                                // let adjustedPageSize = tableMetaData.pageSize;
                                // if (page === totalPages) {
                                //     const remainingItems = tableMetaData.total % tableMetaData.pageSize;
                                //     if (remainingItems > 0) {
                                //         adjustedPageSize = remainingItems;
                                //     }
                                // }
                                loadRows(page, pageSize)
                            }}
                        />
                }
                {
                    customColumns && cabinetData?.length > 0 &&
                        <Pagination
                            current={cabinetPagination.page}
                            total={cabinetPagination.totalCount}
                            pageSize={cabinetPagination.pageSize}
                            showSizeChanger={true}
                            pageSizeOptions={[ 10, 30, 60, 100 ]}
                            onChange={(page, pageSize) => {
                                // const totalPages = Math.ceil(tableMetaData.total / tableMetaData.pageSize);
                                // let adjustedPageSize = tableMetaData.pageSize;
                                // if (page === totalPages) {
                                //     const remainingItems = tableMetaData.total % tableMetaData.pageSize;
                                //     if (remainingItems > 0) {
                                //         adjustedPageSize = remainingItems;
                                //     }
                                // }
                                setCabinetPagination((prev)=>({...prev,page,pageSize}))
                            }}
                        />
                }
            </div>

            <Modal
                title="Экспорт таблицы"
                open={showExportModal}
                onOk={copyAllTableData}
                onCancel={() => {
                    setExportFileName("")
                    setShowExportModal(false)
                }}
                confirmLoading={confirmLoading}
                okButtonProps={{
                    disabled: exportFileName.length === 0,
                }}
                okText="Сохранить"
            >
                <Input
                    value={exportFileName}
                    onChange={(e) => setExportFileName(e.target.value)}
                    size="large"
                    placeholder="Введите название файла"
                    allowClear
                />
            </Modal>
            <AddTariff loadRows={loadRows} addTarifModalVisible={addTarifModalVisible} setAddTarifModalVisible={setAddTarifModalVisible}/>
            <EditSchool
                editSchoolModalVisible={editSchoolModalVisible}
                setEditSchoolModalVisible={setEditSchoolModalVisible}
                id={currentEditSchoolData.id}
                loadRows={loadRows}
            />
            <AddOrder
                addOrderModalVisible={addOrderModalVisible}
                setAddOrderModalVisible={setAddOrderModalVisible}
                loadRows={loadRows}
            />
            <EditOrder
                editOrderModalVisible={editOrderModalVisible}
                setEditOrderModalVisible={setEditOrderModalVisible}
                id={editOrderId}
                loadRows={loadRows}
            />
            <AddPayment
                addPaymentModalVisible={addPaymentModalVisible}
                setAddPaymentModalVisible={setAddPaymentModalVisible}
                schoolName={currentAddPaymentData.schoolName}
                id={currentAddPaymentData.id}
                loadRows={loadRows}
            />
            <EditPayment
                updatePaymentModalVisible={editPaymentModalVisible}
                setUpdatePaymentModalVisible={setEditPaymentModalVisible}
                id={currentEditPaymentData.id}
                loadRows={loadRows}
            />
            {editUserInfo.visible && <EditUser id={editUserInfo.id} visible={editUserInfo.visible} setVisible={setEditUserInfo} loadRows={loadRows} />}
        </div>
    )
}

export default ReportTable
